import { combineReducers } from 'redux';
import authReducer from '../ducks/auth';
import notificationReducer from '../ducks/notification';
import apiReducer from '../ducks/api';
import usersReducer from '../ducks/users';
import groupsReducer from '../ducks/groups';
import enterpriseReducer from '../ducks/enterprise';
import genericsReducer from '../ducks/generics';
import productionScheduleReducer from '../ducks/production-schedule';
import discardAllocationReducer from '../ducks/discard-allocation';
import advertisingBudgetReducer from '../ducks/advertising-budget';
import commercialDescriptionReducer from '../ducks/commercial-description';
import groupLineReducer from '../ducks/item-group-line';
import priceListGroupReducer from '../ducks/price-list-group';
import costReportReducer from '../ducks/cost-report';
import cadTiposReducer from '../ducks/cad-types';
import despReducer from '../ducks/desp-vendas/reducers';
import updateTableReducer from '../ducks/update-table';
import featureReducer from '../ducks/feature';
import compositionFeatureReducer from '../ducks/composition-feature';
import itemBoxUnityReducer from '../ducks/item-box-unity';
import itemSituationReducer from '../ducks/item-situation';
import descriptionItemReducer from '../ducks/descriptionItem';
import dataSheetReducer from '../ducks/dataSheet';
import groupItensItemReducer from '../ducks/group-itens';
import SpecificationParametersReducer from '../ducks/specification-parameters';
import parametersResponseReducer from '../ducks/parameters-response';
import paramsForGroupReducer from '../ducks/params-for-group';
import featureResponseReducer from '../ducks/featureResponse';
import productLineReducer from '../ducks/productLine';
import featurePerGroupReducer from '../ducks/feature-per-group';
import sisdevinReducer from '../ducks/sisdevin';
import warehouseReducer from '../ducks/warehouseSit';
import technicalDataReducer from '../ducks/technical-data';
import transferMotivesReducer from '../ducks/transfer-motives';
import additionMotivesReducer from '../ducks/addition-motives';
import freDivergenceReducer from '../ducks/freDivergence';

const rootReducer = combineReducers({
	auth: authReducer,
	notification: notificationReducer,
	api: apiReducer,
	user: usersReducer,
	group: groupsReducer,
	enterprise: enterpriseReducer,
	generics: genericsReducer,
	productionSchedule: productionScheduleReducer,
	discardAllocation: discardAllocationReducer,
	advertisingBudget: advertisingBudgetReducer,
	commercialDescription: commercialDescriptionReducer,
	groupLine: groupLineReducer,
	groupPrice: priceListGroupReducer,
	costReport: costReportReducer,
	updateTable: updateTableReducer,
	cadTipos: cadTiposReducer,
	despVendas: despReducer,
	feature: featureReducer,
	featureResponse: featureResponseReducer,
	compositionFeature: compositionFeatureReducer,
	itemBoxUnityFeature: itemBoxUnityReducer,
	itemSituationFeature: itemSituationReducer,
	descriptionItem: descriptionItemReducer,
	dataSheet: dataSheetReducer,
	descriptionItemFeature: descriptionItemReducer,
	groupItens: groupItensItemReducer,
	groupItensFeature: groupItensItemReducer,
	specificationParametersFeature: SpecificationParametersReducer,
	parametersResponse: parametersResponseReducer,
	paramsForGroup: paramsForGroupReducer,
	productLine: productLineReducer,
	featurePerGroup: featurePerGroupReducer,
	sisdevin: sisdevinReducer,
	warehouse: warehouseReducer,
	technicalData: technicalDataReducer,
	transferMotives: transferMotivesReducer,
	additionMotives: additionMotivesReducer,
	freDivergence: freDivergenceReducer
});

export default rootReducer;
