import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as transferMotivesApi from '../../../api/appApi/transferMotives';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(transferMotivesApi.get, query);

		yield put(actions.setTransferMotives(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Motivos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newTransferMotives(payload) {
	const { transfer } = payload;

	try {
		const response = yield call(transferMotivesApi.newTransfer, transfer);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Motivo cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/stock/transfer-motives');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar motivo.',
				'error',
			),
		);
	}
}

export function* editTransferMotives(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.transfer;

	const { id } = payload;

	try {
		const response = yield call(transferMotivesApi.editTransfer, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Motivo editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/stock/transfer-motives');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Motivos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteTransfer(payload) {
	const { transfer } = payload;

	try {
		const response = yield call(transferMotivesApi.deleteTransfer, transfer);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Motivo deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getTransferMotives(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Motivo.',
				'error',
			),
		);
	}
}

export default function* watchTransferMotives() {
	yield takeLatest(types.GET_TRANSFER_MOTIVES, get);
	yield takeLatest(types.ADD_TRANSFER_MOTIVES, newTransferMotives);
	yield takeLatest(types.EDIT_TRANSFER_MOTIVES, editTransferMotives);
	yield takeLatest(types.DELETE_TRANSFER_MOTIVES, deleteTransfer);
}
