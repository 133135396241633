import * as types from './types';

export const getListPrice = query => ({
	type: types.GET_PRICE_LIST,
	query,
});

export const setListPrice = price => ({
	type: types.SET_PRICE_LIST,
	price,
});

export const getPrice = price => ({
	type: types.GET_PRICE,
	price,
});

export const setPrice = price => ({
	type: types.SET_PRICE,
	price,
});

export const addPriceList = price => ({
	type: types.ADD_PRICE_LIST,
	price,
});

export const editPriceList = (price, id) => ({
	type: types.EDIT_PRICE_LIST,
	price,
	id,
});

export const deletePriceList = price => ({
	type: types.DELETE_PRICE_LIST,
	price,
});

export default {
	getListPrice,
	setListPrice,
	getPrice,
	setPrice,
	addPriceList,
	editPriceList,
	deletePriceList,
};
