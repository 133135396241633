import * as types from './types';


export const getFeature = query => ({
	type: types.GET_FEATURE,
	query,
});

export const setFeature = feature => ({
	type: types.SET_FEATURE,
	feature,
});

export const addFeature = feature => ({
	type: types.ADD_FEATURE,
	feature,
});

export const editFeature = (feature, id) => ({
	type: types.EDIT_FEATURE,
	feature,
	id,
});

export const deleteFeature = feature => ({
	type: types.DELETE_FEATURE,
	feature,
});

export default {
	getFeature,
	setFeature,
	addFeature,
	editFeature,
	deleteFeature
};
