import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as itemGroupLine from '../../../api/appApi/itemGroupLine';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(itemGroupLine.get, query);

		yield put(actions.setListGroupLine(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar grupos das linhas de produtos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getById(payload) {
	yield put(apiActions.apiStart());

	const { group } = payload;

	try {
		const response = yield call(itemGroupLine.getById, group);

		yield put(actions.setGroupLine(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar grupo das linhas de produtos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiEnd());
}

export function* deleteGroupLine(payload) {
	const { group } = payload;

	try {
		const response = yield call(itemGroupLine.deleteGroupLine, group);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo das linhas de produtos deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getListGroupLine(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar grupo das linhas de produtos.',
				'error',
			),
		);
	}
}

export function* newGroupLine(payload) {
	const { group } = payload;

	group.status = true;

	try {
		const response = yield call(itemGroupLine.newGroupLine, group);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo das linhas de produtos cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/grupos-linhas-produto');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar grupo das linhas de produtos.',
				'error',
			),
		);
	}
}

export function* editGroupLine(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.group;

	const { id } = payload;

	try {
		const response = yield call(itemGroupLine.editGroupLine, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo das linhas de produtos editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/grupos-linhas-produto');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar grupo das linhas de produtos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchGroupLine() {
	yield takeLatest(types.GET_GROUP_LINE_LIST, get);
	yield takeLatest(types.DELETE_GROUP_LINE, deleteGroupLine);
	yield takeLatest(types.GET_GROUP_LINE, getById);
	yield takeLatest(types.ADD_GROUP_LINE, newGroupLine);
	yield takeLatest(types.EDIT_GROUP_LINE, editGroupLine);
}
