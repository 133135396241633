import { SET_GROUP_LINE_LIST, SET_GROUP_LINE } from './types';

const initialState = {
	list: [],
	group: false,
};

const groupLineReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_GROUP_LINE_LIST:
			return {
				...state,
				list: action.groups,
			};
		case SET_GROUP_LINE:
			return {
				...state,
				group: action.group,
			};
		default:
			return state;
	}
};

export default groupLineReducer;
