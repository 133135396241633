import * as types from './types';


export const getTransferMotives = query => ({
	type: types.GET_TRANSFER_MOTIVES,
	query,
});

export const setTransferMotives = transfer => ({
	type: types.SET_TRANSFER_MOTIVES,
	transfer,
});

export const setSelect = transfer => ({
	type: types.SELECT_TRANSFER_MOTIVES,
	transfer,
});

export const addTransferMotives = transfer => ({
	type: types.ADD_TRANSFER_MOTIVES,
	transfer,
});

export const editTransferMotives = (transfer, id) => ({
	type: types.EDIT_TRANSFER_MOTIVES,
	transfer,
	id,
});

export const deleteTransferMotives = transfer => ({
	type: types.DELETE_TRANSFER_MOTIVES,
	transfer,
});

export default {
	getTransferMotives,
	setTransferMotives,
	addTransferMotives,
	editTransferMotives,
	deleteTransferMotives,
	setSelect
};
