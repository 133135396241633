import api from './api';

export const getDatasheet = data => api.get('/datasheet/datasheet', { params: data });
export const addDatasheet = data => api.post('/datasheet/datasheet', data);
export const editDatasheet = (data,id) => api.put(`/datasheet/datasheet/${id}`, data);
export const deleteDatasheet = id => api.delete(`/datasheet/datasheet/${id}`);
export const getStruct = itemprid => api.get('/datasheet/material/struct', { params: itemprid });
export const getMaterial = query => api.get('/datasheet/material', { params: query })
export const addMaterial = (query, id) => api.post(`/datasheet/material/${id}`, query);
export const editMaterial = (query, id) => api.put(`/datasheet/material/${id}`, query);

