import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as featureResponseApi from '../../../api/appApi/featureResponse';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(featureResponseApi.get, query);

		yield put(actions.setFeatureResponse(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Respostas de Caracteristicas.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newFeatureResponse(payload) {
	const { feature } = payload;

	feature.status = true;

	try {
		const response = yield call(featureResponseApi.newFeatureResponse, feature);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Resposta de Caracteristica cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/feature-response');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Respostas de Característica.',
				'error',
			),
		);
	}
}

export function* editFeatureResponse(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.feature;

	const { id } = payload;

	try {
		const response = yield call(featureResponseApi.editFeatureResponse, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Resposta de característica editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/feature-response');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Resposta de característica.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteFeatureResponse(payload) {
	const { feature } = payload;

	try {
		const response = yield call(featureResponseApi.deleteFeatureResponse, feature);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Resposta de Característica deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getFeatureResponse(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Resposta de Caracteristica.',
				'error',
			),
		);
	}
}

export default function* watchFeatureResponse() {
	yield takeLatest(types.GET_FEATURE_RESPONSE, get);
	yield takeLatest(types.ADD_FEATURE_RESPONSE, newFeatureResponse);
	yield takeLatest(types.EDIT_FEATURE_RESPONSE, editFeatureResponse);
	yield takeLatest(types.DELETE_FEATURE_RESPONSE, deleteFeatureResponse);
}
