import api from './api';

export const getClassifications = (query) => api.get('/generics/classification-stock', {params: query});

export const getItensByClassification = query =>
	api.get(`generics/item/class/${query}`);

export const getItensByStock = query =>
	api.get(`generics/item/stock-taking/${query}`);

export const getSuppliers = query => api.get(`generics/supplier${query}`);

export const getLine = () => api.get(`generics/line`);

export const getUfs = () => api.get(`generics/region/uf`);

export const getSegments = () => api.get(`generics/customer/segment`);

export const getSalesTable = () => api.get(`generics/sales-table`);

export const getItens = query => api.get(`/generics/item`);

export const getItensUni = query => api.get(`/generics/item/item-uni`);

export const getImgProfile = query => api.get('/user/img-profile', { params: {USER_ID: query} });

export const addImgProfile = query => api.post(`/user/img-profile`, query);

export const getEnterprises = query => api.get('/enterprises', query);
