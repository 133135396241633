export const GET_CLASSIFICATIONS = 'generics/GET_CLASSIFICATIONS';
export const SET_CLASSIFICATIONS = 'generics/SET_CLASSIFICATIONS';
export const GET_ITENS_CLASSIFICATION = 'generics/GET_ITENS_CLASSIFICATION';
export const SET_ITENS = 'generics/SET_ITENS';
export const GET_SUPPLIERS = 'generics/GET_SUPPLIERS';
export const SET_SUPPLIERS = 'generics/SET_SUPPLIERS';
export const SET_ITENS_STOCK = 'generics/SET_ITENS_STOCK';
export const GET_ITENS_STOCK = 'generics/GET_ITENS_STOCK';
export const SET_LINE = 'generics/SET_LINE';
export const GET_LINE = 'generics/GET_LINE';
export const SET_UFS = 'generics/SET_UFS';
export const GET_UFS = 'generics/GET_UFS';
export const SET_SEGMENTS = 'generics/SET_SEGMENTS';
export const GET_SEGMENTS = 'generics/GET_SEGMENTS';
export const SET_SALES_TABLE = 'generics/SET_SALES_TABLE';
export const GET_SALES_TABLE = 'generics/GET_SALES_TABLE';
export const GET_ITENS = 'generics/GET_ITENS';
export const GET_ITENS_UNI = 'generics/GET_ITENS_UNI';
export const SET_ITEM = 'generics/SET_ITEM';
export const SET_ITENS_UNI = 'generics/SET_ITENS_UNI';
export const GET_ENTERPRISES = 'generics/GET_ENTERPRISES';
export const SET_ENTERPRISES = 'generics/SET_ENTERPRISES';

export default {
	GET_CLASSIFICATIONS,
	SET_CLASSIFICATIONS,
	GET_ITENS_CLASSIFICATION,
	SET_ITENS,
	GET_SUPPLIERS,
	SET_SUPPLIERS,
	SET_ITENS_STOCK,
	GET_ITENS_STOCK,
	SET_UFS,
	GET_UFS,
	SET_SEGMENTS,
	GET_SEGMENTS,
	SET_SALES_TABLE,
	GET_SALES_TABLE,
	GET_ITENS,
	SET_ITEM,
	SET_ITENS_UNI,
	GET_ITENS_UNI,
	GET_ENTERPRISES,
	SET_ENTERPRISES
};
