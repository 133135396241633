import api from './api';

export const get = query =>
	api.get('/costs/price-list-group', { params: query });

export const getById = id => api.get(`/costs/price-list-group/${id}`);

export const deleteGroup = id => api.delete(`/costs/price-list-group/${id}`);

export const newGroup = price => api.post('/costs/price-list-group', price);

export const editGroup = (price, id) =>
	api.put(`/costs/price-list-group/${id}`, price);
