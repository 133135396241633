import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as parametersResponseApi from '../../../api/appApi/parametersResponse';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(parametersResponseApi.get, query);

		yield put(actions.setParametersResponse(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Parâmetro.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getGroup(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(parametersResponseApi.getGroup, query);

		console.log(response)
		yield put(actions.setGroup(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Parâmetros.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newParametersResponse(payload) {
	const { feature } = payload;

	feature.status = true;

	try {
		const response = yield call(parametersResponseApi.insert, feature);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetro cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/qualid/cad/parameters-response');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Parâmetro.',
				'error',
			),
		);
	}
}

export function* editParametersResponse(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.feature;

	const { id } = payload;

	try {
		const response = yield call(parametersResponseApi.update, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'parâmetro editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/qualid/cad/parameters-response');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar parâmetro.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteParametersResponse(payload) {
	const { feature } = payload;

	try {
		const response = yield call(parametersResponseApi.remove, feature);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetro deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getParametersResponse(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Parâmetro.',
				'error',
			),
		);
	}
}

export default function* watchParametersResponse() {
	yield takeLatest(types.GET_GROUP, getGroup);
	yield takeLatest(types.GET_PARAMETERS_RESPONSE, get);
	yield takeLatest(types.ADD_PARAMETERS_RESPONSE, newParametersResponse);
	yield takeLatest(types.EDIT_PARAMETERS_RESPONSE, editParametersResponse);
	yield takeLatest(
		types.DELETE_PARAMETERS_RESPONSE,
		deleteParametersResponse,
	);
}
