import api from './api';

export const get = query => api.get('/supplies/item-box-unid', { params: query });

export const deleteItemBoxUnity = id => api.delete(`/supplies/item-box-unid/${id}`);

export const newItemBoxUnity = item =>
	api.post('/supplies/item-box-unid', item);

export const editItemBoxUnity = (item, id) =>
	api.put(`/supplies/item-box-unid/${id}`, item);
