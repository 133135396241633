import * as types from './types';

export const getListGroupLine = query => ({
	type: types.GET_GROUP_LINE_LIST,
	query,
});

export const setListGroupLine = groups => ({
	type: types.SET_GROUP_LINE_LIST,
	groups,
});

export const getGroupLine = group => ({
	type: types.GET_GROUP_LINE,
	group,
});

export const setGroupLine = group => ({
	type: types.SET_GROUP_LINE,
	group,
});

export const addGroupLine = group => ({
	type: types.ADD_GROUP_LINE,
	group,
});

export const editGroupLine = (group, id) => ({
	type: types.EDIT_GROUP_LINE,
	group,
	id,
});

export const deleteGroupLine = group => ({
	type: types.DELETE_GROUP_LINE,
	group,
});

export default {
	getListGroupLine,
	setListGroupLine,
	getGroupLine,
	setGroupLine,
	addGroupLine,
	editGroupLine,
	deleteGroupLine,
};
