export const GET_PARAMS_FOR_GROUP_LIST = 'params-for-group/GET_PARAMS_FOR_GROUP_LIST';
export const SET_PARAMS_FOR_GROUP_LIST = 'params-for-group/SET_PARAMS_FOR_GROUP_LIST';
export const GET_GROUP = 'params-for-group/GET_GROUP';
export const SET_GROUP = 'params-for-group/SET_GROUP';
export const SET_PARAMS_FOR_GROUP = 'params-for-group/SET_PARAMS_FOR_GROUP';
export const GET_PARAMS_FOR_GROUP = 'params-for-group/GET_PARAMS_FOR_GROUP';
export const ADD_PARAMS_FOR_GROUP = 'params-for-group/ADD_PARAMS_FOR_GROUP';
export const EDIT_PARAMS_FOR_GROUP = 'params-for-group/EDIT_PARAMS_FOR_GROUP';
export const DELETE_PARAMS_FOR_GROUP = 'params-for-group/DELETE_PARAMS_FOR_GROUP';

export default {
	GET_PARAMS_FOR_GROUP_LIST,
	SET_PARAMS_FOR_GROUP_LIST,
	SET_PARAMS_FOR_GROUP,
	GET_PARAMS_FOR_GROUP,
	ADD_PARAMS_FOR_GROUP,
	EDIT_PARAMS_FOR_GROUP,
	DELETE_PARAMS_FOR_GROUP,
	GET_GROUP,
	SET_GROUP
};
