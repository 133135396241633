import api from './api';

export const get = query =>
	api.get('/costs/item-commercial-description', { params: query });

export const deleteCommercial = id =>
	api.delete(`/costs/item-commercial-description/${id}`);

export const newCommercial = commercial =>
	api.post('/costs/item-commercial-description', commercial);

export const editCommercial = (commercial, id) =>
	api.put(`/costs/item-commercial-description/${id}`, commercial);
