import { SET_ADDITION_MOTIVES, SELECT_ADDITION_MOTIVES } from './types';

const initialState = {
	list: [],
	select: false,
};

const additionMotivesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ADDITION_MOTIVES:
			return {
				...state,
				list: action.addition,
			};
		case SELECT_ADDITION_MOTIVES:
			return {
				...state,
				select: action.addition,
			};
		default:
			return state;
	}
};

export default additionMotivesReducer;
