export const GET_SPECIFICATION_PARAMETERS =
	'Specification-parameters/GET_SPECIFICATION_PARAMETERS';
export const SET_SPECIFICATION_PARAMETERS =
	'Specification-parameters/SET_SPECIFICATION_PARAMETERS';
export const ADD_SPECIFICATION_PARAMETERS =
	'Specification-parameters/ADD_SPECIFICATION_PARAMETERS';
export const EDIT_SPECIFICATION_PARAMETERS =
	'Specification-parameters/EDIT_SPECIFICATION_PARAMETERS';
export const DELETE_SPECIFICATION_PARAMETERS =
	'Specification-parameters/DELETE_SPECIFICATION_PARAMETERS';
export const SET_SELECT_SPECIFICATION_PARAMETERS =
	'Specification-parameters/SET_SELECT_SPECIFICATION_PARAMETERS';

export default {
	GET_SPECIFICATION_PARAMETERS,
	SET_SPECIFICATION_PARAMETERS,
	ADD_SPECIFICATION_PARAMETERS,
	EDIT_SPECIFICATION_PARAMETERS,
	DELETE_SPECIFICATION_PARAMETERS,
	SET_SELECT_SPECIFICATION_PARAMETERS
};
