export const GET_SCHEDULE = 'production-schedule/GET_SCHEDULE';
export const SET_SCHEDULE = 'production-schedule/SET_SCHEDULE';
export const GET_PURCHASE_ORDERS = 'production-schedule/GET_PURCHASE_ORDERS';
export const SET_PURCHASE_ORDERS = 'production-schedule/SET_PURCHASE_ORDERS';
export const GET_PROGR = 'production-schedule/GET_PROGR';
export const SET_PROGR = 'production-schedule/SET_PROGR';
export const SET_DATE = 'production-schedule/SET_DATE';


export default {
	GET_SCHEDULE,
	SET_SCHEDULE,
	GET_PURCHASE_ORDERS,
	SET_PURCHASE_ORDERS,
	GET_PROGR,
	SET_DATE,
	SET_PROGR,

	
};
