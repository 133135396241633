export const GET_HISTORIC_DISCARD = 'discard-allocation/GET_HISTORIC_DISCARD';
export const SET_HISTORIC_DISCARD = 'discard-allocation/SET_HISTORIC_DISCARD';
export const DELETE_DISCARD = 'discard-allocation/DELETE_DISCARD';
export const GET_DISCARD_LIST = 'discard-allocation/GET_DISCARD_LIST';
export const SET_DISCARD_LIST = 'discard-allocation/SET_DISCARD_LIST';
export const NEW_DISCARD = 'discard-allocation/NEW_DISCARD';
export const SELECT_DISCARD = 'discard-allocation/SELECT_DISCARD';
export const SET_ITEMS_DISCARD = 'discard-allocation/SET_ITEMS_DISCARD';
export const INITIAL_ITEMS_DISCARD = 'discard-allocation/INITIAL_ITEMS_DISCARD';
export const EDIT_ITEM_DISCARD = 'discard-allocation/EDIT_ITEM_DISCARD';
export const ADD_ITEM_DISCARD = 'discard-allocation/ADD_ITEM_DISCARD';
export const RESET_ITEMS_DISCARD = 'discard-allocation/RESET_ITEMS_DISCARD';

export default {
	GET_HISTORIC_DISCARD,
	SET_HISTORIC_DISCARD,
	DELETE_DISCARD,
	GET_DISCARD_LIST,
	SET_DISCARD_LIST,
	NEW_DISCARD,
	SELECT_DISCARD,
	SET_ITEMS_DISCARD,
	EDIT_ITEM_DISCARD,
	ADD_ITEM_DISCARD,
	RESET_ITEMS_DISCARD,
	INITIAL_ITEMS_DISCARD,
};
