import {
	SET_LIST_COMMERCIAL,
	SELECT_COMMERCIAL,
	MODAL_COMMERCIAL,
	ADD_ITEM_COMMERCIAL,
	EDIT_ITEM_COMMERCIAL,
	RESET_ITEMS_COMMERCIAL,
	DELETE_ITEM_COMMERCIAL,
} from './types';

const initialState = {
	list: [],
	commercial: false,
	modal: false,
	items: [],
};

const commercialDescriptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LIST_COMMERCIAL:
			return {
				...state,
				list: action.list,
			};
		case SELECT_COMMERCIAL:
			return {
				...state,
				commercial: action.commercial,
			};
		case MODAL_COMMERCIAL:
			return {
				...state,
				modal: !state.modal,
			};
		case ADD_ITEM_COMMERCIAL:
			return {
				...state,
				items: state.items.concat(action.item),
			};
		case EDIT_ITEM_COMMERCIAL:
			return {
				...state,
				items: state.items.map((i, key) =>
					key === action.index ? action.item : i,
				),
			};
		case RESET_ITEMS_COMMERCIAL:
			return {
				...state,
				items: [],
			};
		case DELETE_ITEM_COMMERCIAL:
			return {
				...state,
				items: state.items.filter((_, i) => i !== action.index),
			};
		default:
			return state;
	}
};

export default commercialDescriptionReducer;
