import { SET_SCHEDULE, SET_PURCHASE_ORDERS, SET_DATE, SET_PROGR } from './types';

const initialState = {
	schedule: [],
	purchaseOrdersData: [],
	programacao: false,
};

const productionScheduleReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SCHEDULE:
			return {
				...state,
				schedule: action.schedule,
			};
		case SET_PURCHASE_ORDERS:
			return {
				...state,
				purchaseOrdersData: action.purchaseOrdersData,
			};
		case SET_DATE:
			return {
				...state,
				filter: action.data
			}
		case SET_PROGR:
			return {
				...state,
				programacao: action.data
			}
		default:
			return state;
	}
};

export default productionScheduleReducer;
