import api from './api';

export const newDesp= desp =>
	api.post('/desp/setDesp', desp);

	export const get = query =>
	api.get('/desp/get', { params: query });

	export const update = tipo =>
	api.put(`/desp/editDesp/${tipo.id}`, tipo); 

	export const deleteDesp = id =>
	api.delete(`/desp/deletDesp/${id}`);