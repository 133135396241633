import strings from './strings';
import images from './images';
import colors from './colors';
import fonts from './fonts';

const R = {
	strings,
	images,
	colors,
	fonts,
};

export default R;
