import { ADD_MATERIAL, SELECT_MATERIAL, SET_DATASHEET, SET_MATERIAL, SELECT_DATASHEET, MODAL_IMG } from './types';

const initialState = {
	datasheet: [],
	materialList: [],
	selectMaterial: null,
	selectDatasheet: null,
	modalImg: false,
	img: false,
};

const DataSheetReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_MATERIAL:
			return {
				...state,
				materialList: action.material,
				selectMaterial: null
			};
		case SELECT_MATERIAL:
			return {
				...state,
				selectMaterial: action.material
			};
		case SET_DATASHEET:
			return {
				...state,
				datasheet: action.datasheet
			};
		case SET_MATERIAL:
			return {
				...state,
				materialList: action.material
			};
		case SELECT_DATASHEET:
		return {
			...state,
			selectDatasheet: action.datasheet
		};
		case MODAL_IMG:
		return {
			...state,
			modalImg: !state.modalImg,
			img: action.img
		};
		default:
			return state;
	}
};

export default DataSheetReducer;
