import { SET_FEATURE_PER_GROUP_LIST, SET_FEATURE_PER_GROUP, SET_GROUP } from './types';

const initialState = {
	list: [],
	feature: false,
	groups: []
};

const featurePerGroupReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FEATURE_PER_GROUP_LIST:
			return {
				...state,
				list: action.feature,
			};
		case SET_GROUP:
			return {
				...state,
				groups: action.feature,
			};
		case SET_FEATURE_PER_GROUP:
			return {
				...state,
				feature: action.feature,
			};
		default:
			return state;
	}
};

export default featurePerGroupReducer;
