import { SET_FEATURE_RESPONSE, SET_SELECT_FEATURE_RESPONSE } from './types';

const initialState = {
	list: [],
	selecFeature: null,

};

const FeatureResponseReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FEATURE_RESPONSE:
			return {
				...state,
				list: action.feature,
			};
		case SET_SELECT_FEATURE_RESPONSE:
			return {
				...state,
				selecFeature: action.feature,
			};
		default:
			return state;
	}
};

export default FeatureResponseReducer;
