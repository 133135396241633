import * as types from './types';


export const getGroup = query => ({
	type: types.GET_GROUP,
	query,
});

export const setGroup = params => ({
	type: types.SET_GROUP,
	params,
});

export const getParametersResponse = query => ({
	type: types.GET_PARAMETERS_RESPONSE,
	query,
});

export const setParametersResponse = feature => ({
	type: types.SET_PARAMETERS_RESPONSE,
	feature,
});

export const addParametersResponse = feature => ({
	type: types.ADD_PARAMETERS_RESPONSE,
	feature,
});

export const editParametersResponse = (feature, id) => ({
	type: types.EDIT_PARAMETERS_RESPONSE,
	feature,
	id,
});

export const deleteParametersResponse = feature => ({
	type: types.DELETE_PARAMETERS_RESPONSE,
	feature,
});

export const setSelectParametersResponse = feature => ({
	type: types.SET_SELECT_PARAMETERS_RESPONSE,
	feature,
});

export default {
	getParametersResponse,
	setParametersResponse,
	addParametersResponse,
	editParametersResponse,
	deleteParametersResponse,
	setSelectParametersResponse,
	getGroup,
	setGroup
};
