import { SET_GROUP_ITENS, SET_SELECT_GROUP } from './types';

const initialState = {
	list: [],
	group: null
};

const groupItensReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_GROUP_ITENS:
			return {
				...state,
				list: action.item,
			};
		case SET_SELECT_GROUP:
			return {
				...state,
				group: action.item,
			};
		default:
			return state;
	}
};

export default groupItensReducer;
