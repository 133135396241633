import * as types from './types';

const initialState = {
	list: false,
	almox: false,
};

const warehouseReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_LIST:
			return {
				...state,
				list: action.warehouses,
			};
		case types.SET_LIST_ALMOX:
			return {
				...state,
				almox: action.almox,
			};
		default:
			return state;
	}
};

export default warehouseReducer;
