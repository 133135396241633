import * as types from './types';

export const getGroupItens = query => ({
	type: types.GET_GROUP_ITENS,
	query,
});

export const setGroupItens = item => ({
	type: types.SET_GROUP_ITENS,
	item,
});

export const addGroupItens = item => ({
	type: types.ADD_GROUP_ITENS,
	item,
});

export const setSelectGroupItens = item => ({
	type: types.SET_SELECT_GROUP,
	item,
});

export const editGroupItens = (item, id) => ({
	type: types.EDIT_GROUP_ITENS,
	item,
	id,
});

export const deleteGroupItens = id => ({
	type: types.DELETE_GROUP_ITENS,
	id,
});

export default {
	getGroupItens,
	setGroupItens,
	addGroupItens,
	editGroupItens,
	deleteGroupItens,
	setSelectGroupItens,
};
