import * as types from './types';

export const getItemBoxUnity = query => ({
	type: types.GET_ITEM_BOX_UNITY,
	query,
});

export const setItemBoxUnity = item => ({
	type: types.SET_ITEM_BOX_UNITY,
	item,
});

export const addItemBoxUnity = item => ({
	type: types.ADD_ITEM_BOX_UNITY,
	item,
});

export const editItemBoxUnity = (item, id) => ({
	type: types.EDIT_ITEM_BOX_UNITY,
	item,
	id,
});

export const deleteItemBoxUnity = item => ({
	type: types.DELETE_ITEM_BOX_UNITY,
	item,
});

export const setSelectItemBoxUnity = item => ({
	type: types.SET_SELECT_ITEM_BOX_UNITY,
	item,
});

export default {
	getItemBoxUnity,
	setItemBoxUnity,
	addItemBoxUnity,
	editItemBoxUnity,
	deleteItemBoxUnity,
	setSelectItemBoxUnity,
};
