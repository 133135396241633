import api from './api';

export const get = query =>
    api.get('/quality/params-for-group', { params: query });

export const getGroup = query =>
    api.get('/quality/params-for-group/group', { params: query });

export const remove = id => api.delete(`/quality/params-for-group/${id}`);

export const add = params => api.post('/quality/params-for-group', params);

export const edit = (params, id) =>
    api.put(`/quality/params-for-group/${id}`, params);
