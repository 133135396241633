import * as types from './types';

export const getHistoric = query => ({
	type: types.GET_HISTORIC_DISCARD,
	query,
});

export const setHistoric = historic => ({
	type: types.SET_HISTORIC_DISCARD,
	historic,
});

export const deleteDiscard = discard => ({
	type: types.DELETE_DISCARD,
	discard,
});

export const getDiscardList = query => ({
	type: types.GET_DISCARD_LIST,
	query,
});

export const setDiscardList = list => ({
	type: types.SET_DISCARD_LIST,
	list,
});

export const newDiscard = discard => ({
	type: types.NEW_DISCARD,
	discard,
});

export const selectDiscard = discard => ({
	type: types.SELECT_DISCARD,
	discard,
});

export const setItems = item => ({
	type: types.SET_ITEMS_DISCARD,
	item,
});

export const editItem = (item, index) => ({
	type: types.EDIT_ITEM_DISCARD,
	item,
	index,
});

export const addItem = item => ({
	type: types.ADD_ITEM_DISCARD,
	item,
});

export const resetItems = () => ({
	type: types.RESET_ITEMS_DISCARD,
});

export const initialItems = items => ({
	type: types.INITIAL_ITEMS_DISCARD,
	items,
});

export default {
	getHistoric,
	setHistoric,
	deleteDiscard,
	getDiscardList,
	setDiscardList,
	newDiscard,
	selectDiscard,
	setItems,
	editItem,
	addItem,
	resetItems,
	initialItems,
};
