import api from './api';

export const get = query =>
    api.get('/commercial/product-line', { params: query });

export const getById = id => api.get(`/commercial/product-line/${id}`);

export const deleteProductLine = id => api.delete(`/commercial/product-line/${id}`);

export const newProductLine = product => api.post('/commercial/product-line', product);

export const editProductLine = (product, id) =>
    api.put(`/commercial/product-line/${id}`, product);
