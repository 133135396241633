import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as technicalApi from '../../../api/appApi/technicalData';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(technicalApi.get, query);

		yield put(actions.setTechnical(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Dados técnicos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchTechnicalData() {
	yield takeLatest(types.GET_TECHNICAL_DATA, get);
}
