export const SET_PRICE_LIST = 'price-list-group/SET_PRICE_LIST';
export const GET_PRICE_LIST = 'price-list-group/GET_PRICE_LIST';
export const SET_PRICE = 'price-list-group/SET_PRICE';
export const GET_PRICE = 'price-list-group/GET_PRICE';
export const ADD_PRICE_LIST = 'price-list-group/ADD_PRICE_LIST';
export const EDIT_PRICE_LIST = 'price-list-group/EDIT_PRICE_LIST';
export const DELETE_PRICE_LIST = 'price-list-group/DELETE_PRICE_LIST';

export default {
	SET_PRICE_LIST,
	GET_PRICE_LIST,
	SET_PRICE,
	GET_PRICE,
	ADD_PRICE_LIST,
	EDIT_PRICE_LIST,
	DELETE_PRICE_LIST,
};
