const colors = {
	colorPrimary: '#3f1030',
	colorSecondary: '#14030f',
	colorSuccess: '#45b649',
	colorDanger: '#f85032',
	colorWarning: '#ffd700',
	colorInfo: '#00c9ff',
	colorTertiary: '#c18f00',
};

export default colors;
