export const GET_SISDEVIN = 'sisdevin/GET_TYPES_SISDEVIN';
export const SET_SISDEVIN = 'sisdevin/SET_SISDEVIN';
export const ADD_SISDEVIN = 'sisdevin/ADD_SISDEVIN';
export const EDIT_SISDEVIN = 'sisdevin/EDIT_SISDEVIN';
export const DELETE_SISDEVIN = 'sisdevin/DELETE_SISDEVIN';
export const SELECT_SISDEVIN = 'sisdevin/SELECT_SISDEVIN';

export default {
	GET_SISDEVIN,
	SET_SISDEVIN,
	ADD_SISDEVIN,
	EDIT_SISDEVIN,
	DELETE_SISDEVIN,
	SELECT_SISDEVIN
};
