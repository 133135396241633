import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as discardAllocation from '../../../api/appApi/discardAllocation';
import { navigate } from '../../../lib/utils/navigation';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';

export function* getHistoric(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(discardAllocation.getHistoric, query);

		yield put(actions.setHistoric(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar históricos de rateio de descartes.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteDiscard(payload) {
	const { discard } = payload;

	try {
		const response = yield call(discardAllocation.deleteDiscard, discard);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Rateio de descartes deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getHistoric(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar histórico de rateio de descartes.',
				'error',
			),
		);
	}
}

export function* getList(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(discardAllocation.getList, query);

		yield put(actions.resetItems());
		yield put(actions.setDiscardList(response.data.data));

		const items = [];

		response.data.data.forEach(element => {
			element.data.forEach(item => {
				items.push(item);
			});
		});

		yield put(actions.initialItems(items));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar lista para rateio de descartes.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newDiscard(payload) {
	const { discard } = payload;
	const items = yield select(selectors.getItems);
	const query = yield select(apiSelectors.getQuery);

	const data = {
		description: discard,
		start: query.start,
		end: query.end,
		items,
	};

	try {
		const response = yield call(discardAllocation.newDiscard, data);
		if (response.data) {
			yield put(
				notificationActions.addNotification(
					'Sucesso ao realizar rateio de descartes.',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/rateio-descartes');
			}, 1200);
		}
	} catch (error) {
		const { response } = error;
		if (response.data) {
			yield put(
				notificationActions.addNotification(
					response.data.data,
					'error',
				),
			);
		} else {
			yield put(
				notificationActions.addNotification(
					'Erro ao realizar rateio de descartes.',
					'error',
				),
			);
		}
	}
}

export function* setItems(payload) {
	const { item } = payload;

	const items = yield select(selectors.getItems);
	const add = items.some(val => item.id === val.id);

	if (!add) {
		yield put(actions.addItem(item));
	} else {
		const index = items.findIndex(v => v.id === item.id);
		yield put(actions.editItem(item, index));
	}
}

export default function* watchDiscardAllocation() {
	yield takeLatest(types.GET_HISTORIC_DISCARD, getHistoric);
	yield takeLatest(types.DELETE_DISCARD, deleteDiscard);
	yield takeLatest(types.GET_DISCARD_LIST, getList);
	yield takeLatest(types.NEW_DISCARD, newDiscard);
	yield takeLatest(types.SET_ITEMS_DISCARD, setItems);
}
