import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdLens } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem, NavLink as BSNavLink } from 'reactstrap';
import PropTypes from '../../../lib/utils/propTypes';
import bn from '../../../lib/utils/bemnames';

const bem = bn.create('sidebar');

const SidebarPage = ({
	isOpen,
	pageContents,
	namePages,
	IconPage,
	modules,
	click,
	...restProps
}) => {
	const [open, setOpen] = useState({ isOpenSistema: false });

	const handleClick = name => () => {
		setOpen(prevState => {
			const isOpen = prevState[`isOpen${name}`];
			return {
				[`isOpen${name}`]: !isOpen,
			};
		});
	};

	const submodulePermission = (submodules, exact, name, namePages) => {
		function compareNumbers(a, b) {
			return a.order - b.order;
		};

		let sub = modules.filter(index => index.name == namePages)[0]
			? modules.filter(index => index.name == namePages)[0].submodules
			: [];
		sub = sub.map(k => ({
			id: k.id,
			name: k.name,
			order: submodules.filter(i => i.name == k.name)[0]?.order,
			to: submodules.filter(i => i.name == k.name)[0]?.to
				? submodules.filter(i => i.name == k.name)[0]?.to
				: '/none',
		}));
		sub.sort(compareNumbers);
		
		return sub.map((i, k) => (
			<Collapse
				isOpen={open[`isOpen${name}`]}
				style={{ marginLeft: '40px' }}>
				<NavItem
					key={i.name}
					className={bem.e('nav-item nav-sub-item')}>
					<BSNavLink
						id={`navItem-${i.name}-${k}`}
						tag={NavLink}
						to={i.to}
						activeClassName="active"
						exact={exact}>
						<MdLens className={bem.e('nav-item-icon icon-small')} />
						<span className="">{i.name}</span>
					</BSNavLink>
				</NavItem>
			</Collapse>
		));
	};
	return (
		<div {...restProps} style={{ display: 'contents' }}>
			<NavItem className={bem.e('nav-item')} onClick={click(namePages)}>
				<BSNavLink className={bem.e('nav-item-collapse')}>
					<div className="d-flex">
						<IconPage className={bem.e('nav-item-icon')} />
						<span className="nav-name">{namePages}</span>
					</div>
					<MdKeyboardArrowDown
						className={bem.e('nav-item-icon')}
						style={{
							padding: 0,
							transform: isOpen
								? 'rotate(0deg)'
								: 'rotate(-90deg)',
							transitionDuration: '0.3s',
							transitionProperty: 'transform',
						}}
					/>
				</BSNavLink>
			</NavItem>
			<Collapse isOpen={isOpen}>
				{pageContents.map(
					(
						{ to, name, exact, isModule, desc, submodules, Icon },
						index,
					) =>
						!isModule ? (
							<NavItem
								key={name}
								className={bem.e('nav-item nav-sub-item')}>
								<BSNavLink
									id={`navItem-${name}-${index}`}
									tag={NavLink}
									to={to}
									activeClassName="active"
									exact={exact}
									style={{ marginLeft: '20px' }}>
									<MdLens
										className={bem.e(
											'nav-item-icon icon-small',
										)}
									/>
									<span className="">{name}</span>
								</BSNavLink>
							</NavItem>
						) : (
							<>
								<NavItem
									className={bem.e('nav-item nav-sub-item')}
									onClick={handleClick(name)}
									style={{ marginLeft: '30px' }}>
									<BSNavLink
										className={bem.e('nav-item-collapse')}
										id={`navItem-${name}-${index}`}>
										<div className="d-flex">
											<Icon
												className={bem.e(
													'nav-item-icon',
												)}
											/>
											<span className="nav-name">
												{desc}
											</span>
										</div>
										<MdKeyboardArrowDown
											className={bem.e('nav-item-icon')}
											style={{
												padding: 0,
												transform: isOpen
													? 'rotate(0deg)'
													: 'rotate(-90deg)',
												transitionDuration: '0.3s',
												transitionProperty: 'transform',
											}}
										/>
									</BSNavLink>
								</NavItem>
								{submodulePermission(
									submodules,
									exact,
									name,
									name,
								)}
							</>
						),
				)}
			</Collapse>
		</div>
	);
};

SidebarPage.propTypes = {
	namePages: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
	IconPage: PropTypes.elementType.isRequired,
	pageContents: PropTypes.arrayOf(PropTypes.object).isRequired,
	click: PropTypes.func.isRequired,
};

SidebarPage.defaultProps = {
	isOpen: false,
};

export default SidebarPage;
