import { SET_FEATURE } from './types';

const initialState = {
	list: [],
};

const featureReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FEATURE:
			return {
				...state,
				list: action.feature,
			};
		default:
			return state;
	}
};

export default featureReducer;
