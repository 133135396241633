export const GET_FEATURE_RESPONSE = 'feature-response/GET_FEATURE_RESPONSE';
export const SET_FEATURE_RESPONSE = 'feature-response/SET_FEATURE_RESPONSE';
export const ADD_FEATURE_RESPONSE = 'feature-response/ADD_FEATURE_RESPONSE';
export const EDIT_FEATURE_RESPONSE = 'feature-response/EDIT_FEATURE_RESPONSE';
export const DELETE_FEATURE_RESPONSE = 'feature-response/DELETE_FEATURE_RESPONSE';
export const SET_SELECT_FEATURE_RESPONSE = 'feature-response/SET_SELECT_FEATURE_RESPONSE';


export default {
	GET_FEATURE_RESPONSE,
	SET_FEATURE_RESPONSE,
	ADD_FEATURE_RESPONSE,
	EDIT_FEATURE_RESPONSE,
	DELETE_FEATURE_RESPONSE,
	SET_SELECT_FEATURE_RESPONSE
};
