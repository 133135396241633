import random from 'faker/lib/random';
import moment from 'moment';

export const randomNum = (min = 0, max = 1000) => random().number({ min, max });

/* eslint-disable no-mixed-operators */
export const removeEmpty = obj => {
	Object.entries(obj).forEach(
		([key, val]) =>
			(val && typeof val === 'object' && removeEmpty(val)) ||
			((val === null ||
				val === '' ||
				val === undefined ||
				val === 'Invalid date') &&
				// eslint-disable-next-line no-param-reassign
				delete obj[key]),
	);

	return obj;
};

export const getDate = () => {
	const data = new Date();
	const semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
	const meses = [
		"Janeiro",
		"Fevereiro",
		"Março",
		"Abril",
		"Maio",
		"Junho",
		"Julho",
		"Agosto",
		"Setembro",
		"Outubro",
		"Novembro",
		"Dezembro"
	];

	return `${semana[data.getDay()]}, ${data.getDate()} de ${meses[data.getMonth()]} de 2022`
}

export const commaSeparatedString = arrayObjects => {
	let result = '';
	if (arrayObjects) {
		result = Array.prototype.map
			.call(arrayObjects, item => item.name)
			.join(', ');
	}
	return result;
};

export const today = () => moment(new Date()).format('DD/MM/YYYY');

export const formatDate = value => moment(value).format('DD/MM/YYYY');

export const formatNumber = num => {
	return num.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1.');
};

export const clearFormatNumber = num => {
	let vlrTmp = 0;
	vlrTmp = num + '';
	while(vlrTmp.includes(',') || vlrTmp.includes('.')) {
		vlrTmp = vlrTmp.replace('.','');
		vlrTmp = vlrTmp.replace(',','');	
	}
	return vlrTmp;
}

export const formatThreeDecimals = num => {
	num = num + '';
	num = num[0] == '0' ? num.substr(1) : num;
	return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}