import React from 'react';
import PropTypes from '../../../../lib/utils/propTypes';

const col = {
	padding: '5px 0',
};

const table = {
	width: '100%',
	tableLayout: 'fixed',
};

const border = {
	width: '100%',
	borderBottom: '1px #E0E0E0 solid',
};

const RowBodyTable = ({ name, ...restProps }) => {
	return (
		<div {...restProps} style={{ width: '100%' }}>
			<table style={table}>
				<tbody>
					<tr style={border}>
						<td colSpan="4" style={col}>
							{name}
						</td>
					</tr>
					<tr style={{ width: '100%' }}>
						<td width="25%" style={col}>
							Valor
						</td>
						<td width="25%" style={col}>
							ST
						</td>
						<td width="25%" style={col}>
							IPI
						</td>
						<td width="25%" style={col}>
							UNIT
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

RowBodyTable.propTypes = {
	name: PropTypes.string.isRequired,
};

export default RowBodyTable;
