import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as commercialDescription from '../../../api/appApi/commercialDescription';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';
import { navigate } from '../../../lib/utils/navigation';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	yield put(actions.resetItems());

	try {
		const response = yield call(commercialDescription.get, query);

		yield put(actions.set(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar descrições comerciais.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteCommercial(payload) {
	const { commercial } = payload;

	try {
		const response = yield call(
			commercialDescription.deleteCommercial,
			commercial,
		);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Descrição comercial deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.get(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar descrição comercial.',
				'error',
			),
		);
	}
}

export function* newCommercial(payload) {
	const { commercial } = payload;

	try {
		const response = yield call(
			commercialDescription.newCommercial,
			commercial,
		);
		if (response.data) {
			yield put(actions.toogleModal());
			yield put(
				notificationActions.addNotification(
					'Sucesso ao realizar cadastro de descrição comercial.',
					'success',
				),
			);

			setTimeout(() => {
				navigate('/descricao-comercial');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao realizar cadastro de descrição comercial.',
				'error',
			),
		);
	}
}

export function* editCommercial(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.commercial;

	const { id } = payload;


	try {
		const response = yield call(commercialDescription.editCommercial, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Descrição editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/descricao-comercial');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar característica.',
				'error',
			),
		);
	}
}

export function* setItems(payload) {
	const { item } = payload;
	const items = yield select(selectors.getItems);
	const add = items.some(val => item.id === val.id);

	if (!add) {
		yield put(actions.addItem(item));
	} else {
		const { field } = payload;
		const index = items.findIndex(v => v.id === item.id);
		yield put(actions.editItem(item, index, field));
	}
}

export default function* watchCommercialDescription() {
	yield takeLatest(types.GET_LIST_COMMERCIAL, get);
	yield takeLatest(types.DELETE_COMMERCIAL, deleteCommercial);
	yield takeLatest(types.NEW_COMMERCIAL, newCommercial);
	yield takeLatest(types.EDIT_COMMERCIAL, editCommercial);
	yield takeLatest(types.SET_ITEMS_COMMERCIAL, setItems);
}
