import * as types from './types';

export const get = query => ({
	type: types.GET_LIST_DESP,
	query,
});

export const set = desp => ({
	type: types.SET_LIST_DESP,
	desp,
});

export const deleteDesp = desp => ({
	type: types.DELETE_DESP,
	desp,
});

export const newDesp = tipo => ({
	type: types.NEW_DESP,
	tipo,
});

export const select = select => ({
	type: types.SELECT_TIPOS,
	select,
});

export const toogleModal = () => ({
	type: types.MODAL_BUDGET,
});

export const setDesp = (desp, field) => ({
	type: types.SET_ITEMS_DESP,
	desp,
	field,
});

export const editItem = (item, index, field) => ({
	type: types.EDIT_ITEM_TIPOS,
	item,
	index,
	field,
});

export const addItem = item => ({
	type: types.ADD_ITEM_BUDGET,
	item,
});

export const editDesp = tipos => ({
	type: types.EDIT_DESP,
	tipos,
});

export const deleteItem = index => ({
	type: types.DELETE_ITEM_BUDGET,
	index,
});

export const resetItems = () => ({
	type: types.RESET_ITEMS_BUDGET,
});

export default {
	get,
	set,
	deleteDesp,
	newDesp,
	select,
	toogleModal,
	setDesp,
	editItem,
	addItem,
	editDesp,
	deleteItem,
	resetItems,
};
