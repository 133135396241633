export const GET_DATASHEET = 'data-sheet/GET_DATASHEET';
export const SET_DATASHEET = 'data-sheet/SET_DATASHEET';
export const ADD_DATASHEET = 'data-sheet/ADD_DATASHEET';
export const SELECT_DATASHEET = 'data-sheet/SELECT_DATASHEET';
export const GET_MATERIAL = 'data-sheet/GET_MATERIAL';
export const SET_MATERIAL = 'data-sheet/SET_MATERIAL';
export const ADD_MATERIAL = 'data-sheet/ADD_MATERIAL';
export const SELECT_MATERIAL = 'data-sheet/SELECT_MATERIAL';
export const GET_STRUCT_MATERIAL = 'data-sheet/GET_STRUCT_MATERIAL';
export const EDIT_DATASHEET = 'data-sheet/EDIT_DATASHEET';
export const DELETE_DATASHEET = 'data-sheet/DELETE_DATASHEET';
export const MODAL_IMG = 'data-sheet/MODAL_IMG';

export default {
	GET_DATASHEET,
	SET_DATASHEET,
	ADD_DATASHEET,
	GET_MATERIAL,
	SET_MATERIAL,
	ADD_MATERIAL,
	SELECT_MATERIAL,
	GET_STRUCT_MATERIAL,
	EDIT_DATASHEET,
	SELECT_DATASHEET,
	DELETE_DATASHEET,
	MODAL_IMG
};
