import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as paramsForGroup from '../../../api/appApi/paramsForGroup';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(paramsForGroup.get, query);

		yield put(actions.setListParamsForGroup(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar parâmetros para grupos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getGroup(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(paramsForGroup.getGroup, query);

		yield put(actions.setGroup(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Caracteristica por grupo.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}


export function* deleteParamsForGroup(payload) {
	const { param } = payload;

	try {
		const response = yield call(paramsForGroup.remove, param);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetro de grupo deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getListParamsForGroup(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Parâmetro de grupo.',
				'error',
			),
		);
	}
}

export function* newParamsForGroup(payload) {
	const { params } = payload;

	try {
		const response = yield call(paramsForGroup.add, params);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetro de grupo cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/qualid/cad/params-for-group');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Parâmetro de grupo.',
				'error',
			),
		);
	}
}

export function* editParamsForGroup(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.param;

	const { id } = payload;

	try {
		const response = yield call(paramsForGroup.edit, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetro de grupo editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/qualid/cad/params-for-group');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Parâmetro de grupo.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchParamsForGroup() {
	yield takeLatest(types.GET_PARAMS_FOR_GROUP_LIST, get);
	yield takeLatest(types.DELETE_PARAMS_FOR_GROUP, deleteParamsForGroup);
	yield takeLatest(types.ADD_PARAMS_FOR_GROUP, newParamsForGroup);
	yield takeLatest(types.EDIT_PARAMS_FOR_GROUP, editParamsForGroup);
	yield takeLatest(types.GET_GROUP, getGroup);
}
