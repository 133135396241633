import { SET_ITEM_SITUATION, SET_SELECT_ITEM_SITUATION } from './types';

const initialState = {
	list: [],
	selecItemSituation: null,
};

const itemSituationReducer = (state = initialState, action) => {
	switch (action) {
		case SET_ITEM_SITUATION:
			return {
				...state,
				list: action.item,
			};
		case SET_SELECT_ITEM_SITUATION:
			return {
				...state,
				selecItemSituation: action.item,
			};
		default:
			return state;
	}
};

export default itemSituationReducer;
