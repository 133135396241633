import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as freDivergence from '../../../api/appApi/freDivergence';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';
import * as cadTypes from '../../../api/appApi/cadTypes'

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;
	try {
		const response = yield call(freDivergence.get, query);
		yield put(actions.set(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Divergencias.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}



export default function* wacthDivergence() {
	yield takeLatest(types.GET_FRE_DIVERGENCE, get);
}
