import * as types from './types';

export const getItemSituation = query => ({
	type: types.GET_ITEM_SITUATION,
	query,
});

export const setItemSituation = item => ({
	type: types.SET_ITEM_SITUATION,
	item,
});

export const addItemSituation = item => ({
	type: types.ADD_ITEM_SITUATION,
	item,
});

export const editItemSituation = (item, id) => ({
	type: types.EDIT_ITEM_SITUATION,
	item,
	id,
});

export const deleteItemSituation = item => ({
	type: types.DELETE_ITEM_SITUATION,
	item,
});

export const setSelectItemSituation = item => ({
	type: types.SET_SELECT_ITEM_SITUATION,
	item,
});

export default {
	getItemSituation,
	setItemSituation,
	addItemSituation,
	editItemSituation,
	deleteItemSituation,
	setSelectItemSituation,
};
