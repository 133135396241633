import { SET_ITEM_BOX_UNITY, SET_SELECT_ITEM_BOX_UNITY } from './types';

const initialState = {
	list: [],
	selecItemBoxUnity: null,
};

const itemBoxUnityReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ITEM_BOX_UNITY:
			return {
				...state,
				list: action.item,
			};
		case SET_SELECT_ITEM_BOX_UNITY:
			return {
				...state,
				selecItemBoxUnity: action.item,
			};
		default:
			return state;
	}
};

export default itemBoxUnityReducer;
