import * as types from './types';


export const getAdditionMotives = query => ({
	type: types.GET_ADDITION_MOTIVES,
	query,
});

export const setAdditionMotives = addition => ({
	type: types.SET_ADDITION_MOTIVES,
	addition,
});

export const setSelect = addition => ({
	type: types.SELECT_ADDITION_MOTIVES,
	addition,
});

export const addAdditionMotives = addition => ({
	type: types.ADD_ADDITION_MOTIVES,
	addition,
});

export const editAdditionMotives = (addition, id) => ({
	type: types.EDIT_ADDITION_MOTIVES,
	addition,
	id,
});

export const deleteAdditionMotives = addition => ({
	type: types.DELETE_ADDITION_MOTIVES,
	addition,
});

export default {
	getAdditionMotives,
	setAdditionMotives,
	addAdditionMotives,
	editAdditionMotives,
	deleteAdditionMotives,
	setSelect
};
