import {
	SET_PARAMETERS_RESPONSE,
	SET_SELECT_PARAMETERS_RESPONSE,
	SET_GROUP
} from './types';

const initialState = {
	list: [],
	selecParametersResponse: null,
	groups: []
};

const ParametersResponseReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PARAMETERS_RESPONSE:
			return {
				...state,
				list: action.feature,
			};
		case SET_GROUP:
			return {
				...state,
				groups: action.params,
			};
		case SET_SELECT_PARAMETERS_RESPONSE:
			return {
				...state,
				selecParametersResponse: action.feature,
			};
		default:
			return state;
	}
};

export default ParametersResponseReducer;
