import api from './api';

export const get = query => api.get('/quality/resp-params-spec', { params: query });

export const getGroup = query =>
	api.get('/quality/resp-params-spec/group', { params: query });

export const getById = id => api.get(`/quality/resp-params-spec/${id}`);

export const remove = id => api.delete(`/quality/resp-params-spec/${id}`);

export const insert = data => api.post('/quality/resp-params-spec', data);

export const update = (data, id) => api.put(`/quality/resp-params-spec/${id}`, data);
