import * as types from './types';

export const getTechnical = query => ({
	type: types.GET_TECHNICAL_DATA,
	query,
});

export const setTechnical = data => ({
	type: types.SET_TECHNICAL_DATA,
	data,
});

export default {
	getTechnical,
	setTechnical,
};
