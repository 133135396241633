import { SET_PRODUCT_LINE, SET_SELECT_PRODUCT_LINE } from './types';

const initialState = {
	list: [],
	selecProductLine: null,
};

const productLineReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PRODUCT_LINE:
			return {
				...state,
				list: action.product,
			};
		case SET_SELECT_PRODUCT_LINE:
			return {
				...state,
				selecProductLine: action.product,
			};
		default:
			return state;
	}
};

export default productLineReducer;
