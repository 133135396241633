import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as cadTypes from '../../../api/appApi/cadTypes';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;


	try {
		const response = yield call(cadTypes.get, query);

		yield put(actions.set(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Tipos.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteTipo(payload) {
	const { tipo } = payload;
	try {
		const response = yield call(cadTypes.deleteTipo, tipo);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Tipo deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.get(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Tipo.',
				'error',
			),
		);
	}
}

export function* newTipo(payload) {
	const { tipo } = payload;
	if(typeof tipo.tipo == 'undefined' || typeof tipo.desc == 'undefined'){
		yield put(
			notificationActions.addNotification(
				'Algum campo está vazio!.',
				'error',
			),
			
		);
	}else{
		try {
			const response = yield call(cadTypes.newTipo, tipo);
			if (response.data) {
				yield put(actions.toogleModal());
				yield put(
					notificationActions.addNotification(
						'Sucesso ao realizar cadastro de tipo.',
						'success',
					),
					
				);
				yield put(actions.get());
			}
		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao realizar cadastro de tipo.',
					'error',
				),
			);
		}
	}
}

export function* editTipo(payload) {
	const { tipos } = payload;
	
	const items = yield select(selectors.getItems);

	const edit = items.find(x => x.id === tipos);

	if (edit.desc === '' ) {
		yield put(
			notificationActions.addNotification(
				'Preencha todos os campos!.',
				'error',
			),
		);
		yield put(actions.get());
	} else {
		try {
			const response = yield call(cadTypes.update, edit);
			if (response.data) {
				const index = items.findIndex(v => v.id === tipos);
				yield put(actions.deleteItem(index));
				yield put(
					notificationActions.addNotification(
						'Sucesso ao editar Tipo.',
						'success',
					),
				);
			}
		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao editar Tipo!.',
					'error',
				),
			);
		}
	}
}

export function* setItems(payload) {
	const { item } = payload;
	const items = yield select(selectors.getItems);
	const add = items.some(val => item.id === val.id);

	if (!add) {
		yield put(actions.addItem(item));
	} else {
		const { field } = payload;
		const index = items.findIndex(v => v.id === item.id);
		yield put(actions.editItem(item, index, field));
	}
}

export default function* wacthAdvertisingBudget() {
	yield takeLatest(types.GET_LIST_TIPOS, get);
	yield takeLatest(types.DELETE_TIPO, deleteTipo);
	yield takeLatest(types.NEW_TYPE, newTipo);
	yield takeLatest(types.EDIT_TIPOS, editTipo);
	yield takeLatest(types.SET_ITEMS_TIPOS, setItems);
}
