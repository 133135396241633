import * as types from './types';


export const getFeatureResponse = query => ({
	type: types.GET_FEATURE_RESPONSE,
	query,
});

export const setFeatureResponse = feature => ({
	type: types.SET_FEATURE_RESPONSE,
	feature,
});

export const addFeatureResponse = feature => ({
	type: types.ADD_FEATURE_RESPONSE,
	feature,
});

export const editFeatureResponse = (feature, id) => ({
	type: types.EDIT_FEATURE_RESPONSE,
	feature,
	id,
});

export const deleteFeatureResponse = feature => ({
	type: types.DELETE_FEATURE_RESPONSE,
	feature,
});

export const setSelectFeatureResponse = feature => ({
	type: types.SET_SELECT_FEATURE_RESPONSE,
	feature,
});

export default {
	getFeatureResponse,
	setFeatureResponse,
	addFeatureResponse,
	editFeatureResponse,
	deleteFeatureResponse,
	setSelectFeatureResponse
};
