export const GET_ITEM_SITUATION = 'stock/cons/item-sit/GET_ITEM_SITUATION';
export const SET_ITEM_SITUATION = 'stock/cons/item-sit/SET_ITEM_SITUATION';
export const ADD_ITEM_SITUATION = 'stock/cons/item-sit/ADD_ITEM_SITUATION';
export const EDIT_ITEM_SITUATION = 'stock/cons/item-sit/EDIT_ITEM_SITUATION';
export const DELETE_ITEM_SITUATION =
	'stock/cons/item-sit/DELETE_ITEM_SITUATION';
export const SET_SELECT_ITEM_SITUATION =
	'stock/cons/item-sit/SET_SELECT_ITEM_SITUATION';

export default {
	GET_ITEM_SITUATION,
	SET_ITEM_SITUATION,
	ADD_ITEM_SITUATION,
	EDIT_ITEM_SITUATION,
	DELETE_ITEM_SITUATION,
	SET_SELECT_ITEM_SITUATION,
};
