import { SET_SPECIFICATION_PARAMETERS, SET_SELECT_SPECIFICATION_PARAMETERS } from './types';

const initialState = {
	list: [],
	select: false
};

const SpecificationParametersReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SPECIFICATION_PARAMETERS:
			return {
				...state,
				list: action.feature,
			};
		case SET_SELECT_SPECIFICATION_PARAMETERS:
			return {
				...state,
				select: action.item,
			};
		default:
			return state;
	}
};

export default SpecificationParametersReducer;
