import * as types from './types';

export const getList = query => ({
	type: types.GET_LIST,
	query,
});

export const setList = warehouses => ({
	type: types.SET_LIST,
	warehouses,
});

export const getListAlmox = query => ({
	type: types.GET_LIST_ALMOX,
	query,
});

export const setListAlmox = almox => ({
	type: types.SET_LIST_ALMOX,
	almox,
});


export default {
	getList,
	setList,
	getListAlmox,
	setListAlmox,
};
