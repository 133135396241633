import * as types from './types';

export const get = query => ({
	type: types.GET_FRE_DIVERGENCE,
	query,
});

export const set = divergence => ({
	type: types.SET_FRE_DIVERGENCE,
	divergence,
});

export const setSelect = divergence => ({
	type: types.SELECT_FRE_DIVERGENCE,
	divergence,
});

export const toogleModal = () => ({
	type: types.MODAL_BUDGET,
});

export default {
	get,
	set,
	setSelect,
	toogleModal
};
