export const GET_DESCRIPTION_ITEM = 'descricao-item/GET_DESCRIPTION_ITEM';
export const SET_DESCRIPTION_ITEM = 'descricao-item/SET_DESCRIPTION_ITEM';
export const ADD_DESCRIPTION_ITEM = 'descricao-item/ADD_DESCRIPTION_ITEM';
export const EDIT_DESCRIPTION_ITEM = 'descricao-item/EDIT_DESCRIPTION_ITEM';
export const DELETE_DESCRIPTION_ITEM = 'descricao-item/DELETE_DESCRIPTION_ITEM';
export const SET_SELECT_DESCRIPTION_ITEM = 'descricao-item/SET_SELECT_DESCRIPTION_ITEM';
export const GET_ALL_ITEMS = 'descricao-item/GET_ALL_ITEMS';
export const SET_ALL_ITEMS = 'descricao-item/SET_ALL_ITEMS';

export default {
	GET_DESCRIPTION_ITEM,
	SET_DESCRIPTION_ITEM,
	ADD_DESCRIPTION_ITEM,
	EDIT_DESCRIPTION_ITEM,
	DELETE_DESCRIPTION_ITEM,
	SET_SELECT_DESCRIPTION_ITEM,
	GET_ALL_ITEMS,
	SET_ALL_ITEMS
};
