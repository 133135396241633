import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as costReport from '../../../api/appApi/costReport';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions } from '../api';
import * as types from './types';
import RowBodyTable from '../../../components/CostResport/CostResportList/RowBodyTable';
import RowHeadTable from '../../../components/CostResport/CostResportList/RowHeadTable';

export function* get(payload) {
	const { query } = payload;

	yield put(apiActions.apiSubmitStart());

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(costReport.getReport, query.table.id);

		const columns = [];

		response.data.data.forEach(group => {
			const line = [];
			group.data.forEach(item => {
				const obj = [
					{
						name: 'Cód.',
						selector: 'code',
						sortable: true,
						right: true,
						width: '8%',
					},
					{
						name: 'Desc. Prod.',
						selector: 'item',
						sortable: true,
						width: '20%',
						wrap: true,
					},
					{
						name: 'ML',
						selector: 'ml',
						sortable: true,
						right: true,
						width: '7%',
					},
				];
				const aux = item.data[0];
				aux.values.forEach((val, index) => {
					obj.push({
						name: <RowBodyTable name={val.description} />,
						selector: val.description,
						cell: row => <RowHeadTable row={row} index={index} />,
					});
					line.push(obj);
				});
				line.push(obj);
			});
			columns.push(line);
		});

		yield put(actions.setColumns(columns));
		yield put(actions.setListReport(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar relatórios de custo.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchCostReport() {
	yield takeLatest(types.GET_REPORT_LIST, get);
}
