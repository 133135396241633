import { SET_COMPOSITION_FEATURE, SET_SELECT_COMPOSITION_FEATURE, SET_ITENS } from './types';

const initialState = {
	list: [],
	selecCompositionFeature: null,
	itens: []
};

const CompositionFeatureReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_COMPOSITION_FEATURE:
			return {
				...state,
				list: action.composition,
			};
		case SET_SELECT_COMPOSITION_FEATURE:
			return {
				...state,
				selecCompositionFeature: action.composition,
			};
		case SET_ITENS:
			return {
				...state,
				itens: action.itens,
			};
		default:
			return state;
	}
};

export default CompositionFeatureReducer;
