import { SET_PARAMS_FOR_GROUP_LIST, SET_PARAMS_FOR_GROUP, SET_GROUP } from './types';

const initialState = {
	list: [],
	params: false,
	groups: []
};

const paramsForGroupReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PARAMS_FOR_GROUP_LIST:
			return {
				...state,
				list: action.params,
			};
		case SET_GROUP:
			return {
				...state,
				groups: action.feature,
		};
		case SET_PARAMS_FOR_GROUP:
			return {
				...state,
				params: action.params,
			};
		default:
			return state;
	}
};

export default paramsForGroupReducer;
