import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as priceListGroup from '../../../api/appApi/priceListGroup';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(priceListGroup.get, query);

		yield put(actions.setListPrice(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar grupos das tabelas de vendas.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getById(payload) {
	yield put(apiActions.apiStart());

	const { price } = payload;

	try {
		const response = yield call(priceListGroup.getById, price);

		yield put(actions.setPrice(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar grupo das tabelas de vendas.',
				'error',
			),
		);
	}

	yield put(apiActions.apiEnd());
}

export function* deleteGroup(payload) {
	const { price } = payload;

	try {
		const response = yield call(priceListGroup.deleteGroup, price);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo das tabelas de vendas deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getListPrice(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar grupo das tabelas de vendas.',
				'error',
			),
		);
	}
}

export function* newGroup(payload) {
	const { price } = payload;

	price.status = true;

	try {
		const response = yield call(priceListGroup.newGroup, price);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo das tabelas de vendas cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/grupos-tabelas-precos');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar grupo das tabelas de vendas.',
				'error',
			),
		);
	}
}

export function* editGroup(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.price;

	data.status = true;

	const { id } = payload;

	try {
		const response = yield call(priceListGroup.editGroup, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo das tabelas de vendas editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/grupos-tabelas-precos');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar grupo das tabelas de vendas.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchGroupPrice() {
	yield takeLatest(types.GET_PRICE_LIST, get);
	yield takeLatest(types.DELETE_PRICE_LIST, deleteGroup);
	yield takeLatest(types.GET_PRICE, getById);
	yield takeLatest(types.ADD_PRICE_LIST, newGroup);
	yield takeLatest(types.EDIT_PRICE_LIST, editGroup);
}
