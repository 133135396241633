import api from './api';

export const get = query =>
	api.get('/laboratory/sisdevin', { params: query });

export const deleteSisdevin = id => api.delete(`/laboratory/sisdevin/${id}`);

export const newSisdevin= sisdevin => api.post('/laboratory/sisdevin', sisdevin);

export const editSisdevin = (sisdevin, id) =>
	api.put(`/laboratory/sisdevin/${id}`, sisdevin);
