import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as featureApi from '../../../api/appApi/feature';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(featureApi.get, query);

		yield put(actions.setFeature(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Caracteristicas.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newFeature(payload) {
	const { feature } = payload;

	feature.status = true;

	try {
		const response = yield call(featureApi.newFeature, feature);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Caracteristica cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/feature');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Característica.',
				'error',
			),
		);
	}
}

export function* editFeature(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.feature;

	const { id } = payload;

	try {
		const response = yield call(featureApi.editFeature, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'característica editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/feature');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar característica.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteFeature(payload) {
	const { feature } = payload;

	try {
		const response = yield call(featureApi.deleteFeature, feature);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Característica deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getFeature(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Caracteristica.',
				'error',
			),
		);
	}
}

export default function* watchFeature() {
	yield takeLatest(types.GET_FEATURE, get);
	yield takeLatest(types.ADD_FEATURE, newFeature);
	yield takeLatest(types.EDIT_FEATURE, editFeature);
	yield takeLatest(types.DELETE_FEATURE, deleteFeature);
}
