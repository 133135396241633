export const GET_GROUP_ITENS = 'comercia/cad/group-itens/GET_GROUP_ITENS';
export const SET_GROUP_ITENS = 'comercia/cad/group-itens/SET_GROUP_ITENS';
export const SET_SELECT_GROUP = 'comercia/cad/group-itens/SET_SELECT_GROUP';
export const ADD_GROUP_ITENS = 'comercia/cad/group-itens/ADD_GROUP_ITENS';
export const EDIT_GROUP_ITENS = 'comercia/cad/group-itens/EDIT_GROUP_ITENS';
export const DELETE_GROUP_ITENS = 'comercia/cad/group-itens/DELETE_GROUP_ITENS';

export default {
	GET_GROUP_ITENS,
	SET_GROUP_ITENS,
	ADD_GROUP_ITENS,
	EDIT_GROUP_ITENS,
	DELETE_GROUP_ITENS,
};
