import { SET_TECHNICAL_DATA } from './types';

const initialState = {
	list: [],
};

const technicalDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TECHNICAL_DATA:
			return {
				...state,
				list: action.data,
			};
		default:
			return state;
	}
};

export default technicalDataReducer;
