import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as productionSchedule from '../../../api/appApi/productionSchedule';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions } from '../api';
import * as types from './types';
import * as selectors from './selectors';

export function* getProductionSchedule(payload) {
	const { filter } = payload;

	yield put(actions.setDate({filter}))
	if (filter.engage) {
		if (
			((filter.engage === '0' || filter.engage === '1') &&
				!filter.dynamic) ||
			((filter.engage === '0' || filter.engage === '1') &&
				filter.dynamic.length === 0)
		) {
			yield put(
				notificationActions.addNotification(
					'Configure ao menos um programado ou considere todos os empenhos.',
					'error',
				),
			);
			return;
		}
	}

	yield put(apiActions.apiSubmitStart());

	yield put(apiActions.setQueryFilter(filter));

	try {
		const response = yield call(productionSchedule.get, filter);

		yield put(actions.setProductionSchedule(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar programação de materiais.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getProg(payload) {

	const data = yield select(selectors.getFilter);
	//console.log(data.filter.start)
	let start = data.filter.start
	let end = data.filter.end
	const {itemId} = payload;

	try {
		const response = yield call(productionSchedule.getProg, start, end, itemId)
		yield put(actions.setProgram(response.data.data));

		
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar programação.',
				'error',
			),
		);
	}
}
export function* getPurchaseOrders(params) {
	yield put(apiActions.apiSubmitStart());

	const { emprId } = params;
	const { itemId } = params;

	try {
		const response = yield call(
			productionSchedule.getPurchaseOrders,
			emprId,
			itemId,
		);
		
		yield put(actions.setPurchaseOrders(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar ordens de compras.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchProductionSchedule() {
	yield takeLatest(types.GET_SCHEDULE, getProductionSchedule);
	yield takeLatest(types.GET_PURCHASE_ORDERS, getPurchaseOrders);
	yield takeLatest(types.GET_PROGR, getProg);

	
}
