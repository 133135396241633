export const GET_COMPOSITION_FEATURE = 'composition-feature/GET_COMPOSITION_FEATURE';
export const SET_COMPOSITION_FEATURE = 'composition-feature/SET_COMPOSITION_FEATURE';
export const ADD_COMPOSITION_FEATURE = 'composition-feature/ADD_COMPOSITION_FEATURE';
export const EDIT_COMPOSITION_FEATURE = 'composition-feature/EDIT_COMPOSITION_FEATURE';
export const DELETE_COMPOSITION_FEATURE = 'composition-feature/DELETE_COMPOSITION_FEATURE';
export const SET_SELECT_COMPOSITION_FEATURE = 'composition-feature/SET_SELECT_COMPOSITION_FEATURE';
export const GET_ITENS = 'composition-feature/GET_ITENS';
export const SET_ITENS = 'composition-feature/SET_ITENS';

export default {
	GET_COMPOSITION_FEATURE,
	SET_COMPOSITION_FEATURE,
	ADD_COMPOSITION_FEATURE,
	EDIT_COMPOSITION_FEATURE,
	DELETE_COMPOSITION_FEATURE,
	SET_SELECT_COMPOSITION_FEATURE,
	GET_ITENS,
	SET_ITENS
};
