import { SET_DESCRIPTION_ITEM, SET_SELECT_DESCRIPTION_ITEM, SET_ALL_ITEMS } from './types';

const initialState = {
	list: [],
	selectDescriptionItem: null,
	itens: []
};

const itemBoxUnityReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DESCRIPTION_ITEM:
			return {
				...state,
				list: action.item,
			};
		case SET_SELECT_DESCRIPTION_ITEM:
			return {
				...state,
				selectDescriptionItem: action.item,
			};
		case SET_ALL_ITEMS:
			return {
				...state,
				itens: action.itens,
			};
		default:
			return state;
	}
};

export default itemBoxUnityReducer;
