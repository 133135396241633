import * as types from './types';

export const getListParamsForGroup = query => ({
	type: types.GET_PARAMS_FOR_GROUP_LIST,
	query,
});

export const setListParamsForGroup = params => ({
	type: types.SET_PARAMS_FOR_GROUP_LIST,
	params,
});

export const getGroup = query => ({
	type: types.GET_GROUP,
	query,
});

export const setGroup = feature => ({
	type: types.SET_GROUP,
	feature,
});

export const getParamsForGroup = params => ({
	type: types.GET_PARAMS_FOR_GROUP,
	params,
});

export const setParamsForGroup = params => ({
	type: types.SET_PARAMS_FOR_GROUP,
	params,
});

export const addParamsForGroup = params => ({
	type: types.ADD_PARAMS_FOR_GROUP,
	params,
});

export const editParamsForGroup = (param, id) => ({
	type: types.EDIT_PARAMS_FOR_GROUP,
	param,
	id,
});

export const deleteParamsForGroup = param => ({
	type: types.DELETE_PARAMS_FOR_GROUP,
	param,
});

export default {
	getListParamsForGroup,
	setListParamsForGroup,
	getParamsForGroup,
	setParamsForGroup,
	addParamsForGroup,
	editParamsForGroup,
	deleteParamsForGroup,
	getGroup,
	setGroup
};
