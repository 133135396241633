import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as productLineApi from '../../../api/appApi/productLine';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(productLineApi.get, query);

		yield put(actions.setProductLine(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Linhas de produto.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newProductLine(payload) {
	const { product } = payload;

	try {
		const response = yield call(
			productLineApi.newProductLine,
			product,
		);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Linha cadastrada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/commercial/cad/product-line');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Linha.',
				'error',
			),
		);
	}
}

export function* editProductLine(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.product;

	const { id } = payload;

	try {
		const response = yield call(productLineApi.editProductLine, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Linha editada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/commercial/cad/product-line');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Linha.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteProductLine(payload) {
	const { product } = payload;

	try {
		const response = yield call(
			productLineApi.deleteProductLine,
			product,
		);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Linha deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getProductLine(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Linha.',
				'error',
			),
		);
	}
}

export default function* watchProductLine() {
	yield takeLatest(types.GET_PRODUCT_LINE, get);
	yield takeLatest(types.ADD_PRODUCT_LINE, newProductLine);
	yield takeLatest(types.EDIT_PRODUCT_LINE, editProductLine);
	yield takeLatest(types.DELETE_PRODUCT_LINE, deleteProductLine);
}
