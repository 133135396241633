import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as sisdevinApi from '../../../api/appApi/sisdevin';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(sisdevinApi.get, query);

		yield put(actions.setSisdevin(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Classificação Sisdevin.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newSisdevin(payload) {
	const { sisdevin } = payload;

	try {
		const response = yield call(sisdevinApi.newSisdevin, sisdevin);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Classificação sisdevin cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/laboratory/cad/sisdevin');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Classificação sisdevin.',
				'error',
			),
		);
	}
}

export function* editSisdevin(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.sisdevin;

	const { id } = payload;

	try {
		const response = yield call(sisdevinApi.editSisdevin, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Classificação sisdevin editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/laboratory/cad/sisdevin');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Classificação sisdevin.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteSisdevin(payload) {
	const { sisdevin } = payload;

	try {
		const response = yield call(sisdevinApi.deleteSisdevin, sisdevin);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Classificação sisdevin deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getSisdevin(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Classificação sisdevin.',
				'error',
			),
		);
	}
}

export default function* watchSisdevin() {
	yield takeLatest(types.GET_SISDEVIN, get);
	yield takeLatest(types.ADD_SISDEVIN, newSisdevin);
	yield takeLatest(types.EDIT_SISDEVIN, editSisdevin);
	yield takeLatest(types.DELETE_SISDEVIN, deleteSisdevin);
}
