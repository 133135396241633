export const GET_LIST = 'almox/GET_LIST';
export const SET_LIST = 'almox/SET_LIST';
export const GET_LIST_ALMOX = 'almox/GET_LIST_ALMOX';
export const SET_LIST_ALMOX = 'almox/SET_LIST_ALMOX';

export default {
	GET_LIST,
	SET_LIST,
	GET_LIST_ALMOX,
	SET_LIST_ALMOX,
};
