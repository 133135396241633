export const GET_TRANSFER_MOTIVES = 'transfer-motives/GET_TYPES_TRANSFER_MOTIVES';
export const SET_TRANSFER_MOTIVES = 'transfer-motives/SET_TRANSFER_MOTIVES';
export const ADD_TRANSFER_MOTIVES = 'transfer-motives/ADD_TRANSFER_MOTIVES';
export const EDIT_TRANSFER_MOTIVES = 'transfer-motives/EDIT_TRANSFER_MOTIVES';
export const DELETE_TRANSFER_MOTIVES = 'transfer-motives/DELETE_TRANSFER_MOTIVES';
export const SELECT_TRANSFER_MOTIVES = 'transfer-motives/SELECT_TRANSFER_MOTIVES';

export default {
	GET_TRANSFER_MOTIVES,
	SET_TRANSFER_MOTIVES,
	ADD_TRANSFER_MOTIVES,
	EDIT_TRANSFER_MOTIVES,
	DELETE_TRANSFER_MOTIVES,
	SELECT_TRANSFER_MOTIVES
};
