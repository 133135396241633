import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as compositionFeatureApi from '../../../api/appApi/compositionFeature';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(compositionFeatureApi.get, query);

		yield put(actions.setCompositionFeature(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Composição.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getItens(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(compositionFeatureApi.getItens, query);

		yield put(actions.setItens(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Itens.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newCompositionFeature(payload) {
	const { composition } = payload;

	composition.status = true;

	try {
		const response = yield call(compositionFeatureApi.newCompositionFeature, composition);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Composição cadastrada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/laboratory/cad/composition-feature');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Composição.',
				'error',
			),
		);
	}
}

export function* editCompositionFeature(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.composition;

	const { id } = payload;

	try {
		const response = yield call(compositionFeatureApi.editCompositionFeature, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Composição editada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/laboratory/cad/composition-feature');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Composição.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteCompositionFeature(payload) {
	const { composition } = payload;

	try {
		const response = yield call(compositionFeatureApi.deleteCompositionFeature, composition);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Composição deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getCompositionFeature(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Composição.',
				'error',
			),
		);
	}
}

export default function* watchCompositionFeature() {
	yield takeLatest(types.GET_COMPOSITION_FEATURE, get);
	yield takeLatest(types.ADD_COMPOSITION_FEATURE, newCompositionFeature);
	yield takeLatest(types.EDIT_COMPOSITION_FEATURE, editCompositionFeature);
	yield takeLatest(types.DELETE_COMPOSITION_FEATURE, deleteCompositionFeature);
	yield takeLatest(types.GET_ITENS, getItens);
}
