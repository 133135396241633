import api from './api';

export const get = query =>
	api.get('/supplies/internal-item-description', { params: query });

export const remove = id =>
	api.delete(`/supplies/internal-item-description/${id}`);

export const insert = item =>
	api.post('/supplies/internal-item-description', item);

export const update = (item, id) =>
	api.put(`/supplies/internal-item-description/${id}`, item);

export const getAllItens = query => api.get(`/supplies/list-all-item`, query);
