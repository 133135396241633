export const GET_FRE_DIVERGENCE = 'free_divergence/GET_FRE_DIVERGENCE';
export const SET_FRE_DIVERGENCE = 'free_divergence/SET_FRE_DIVERGENCE';
export const DELETE_FRE_DIVERGENCE= 'free_divergence/DELETE_TIPO';
export const NEW_FRE_DIVERGENCE = 'free_divergence/NEW_TIPO';
export const SELECT_FRE_DIVERGENCE= 'free_divergence/SELECT_TIPOS';
export const MODAL_BUDGET = 'advertising-budget/MODAL_BUDGET';
export const SET_ITEMS_FRE_DIVERGENCE = 'free_divergence/SET_ITEMS_TIPOS';
export const EDIT_FRE_DIVERGENCE = 'free_divergence/EDIT_ITEM_TIPOS';
export const ADD_FRE_DIVERGENCE = 'advertising-budget/ADD_ITEM_BUDGET';
export const EDIT_TIPOS = 'free_divergence/EDIT_TIPOS';
export const DELETE_ITEM_FRE_DIVERGENCE = 'advertising-budget/DELETE_ITEM_BUDGET';
export const RESET_FRE_DIVERGENCE = 'advertising-budget/RESET_ITEMS_BUDGET';

export default {
	GET_FRE_DIVERGENCE,
	SET_FRE_DIVERGENCE,
	DELETE_FRE_DIVERGENCE,
	NEW_FRE_DIVERGENCE,
	SELECT_FRE_DIVERGENCE,
	MODAL_BUDGET,
	SET_ITEMS_FRE_DIVERGENCE,
	EDIT_FRE_DIVERGENCE,
	ADD_FRE_DIVERGENCE,
	EDIT_TIPOS,
	DELETE_ITEM_FRE_DIVERGENCE,
	RESET_FRE_DIVERGENCE,
};
