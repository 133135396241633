import api from './api';

export const get = query =>
	api.get('/supplies/feature-response', { params: query });

export const getById = id => api.get(`/supplies/feature-response/${id}`);

export const deleteFeatureResponse = id => api.delete(`/supplies/feature-response/${id}`);

export const newFeatureResponse = feature => api.post('/supplies/feature-response', feature);

export const editFeatureResponse = (feature, id) =>
	api.put(`/supplies/feature-response/${id}`, feature);
