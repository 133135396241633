import api from './api';

export const getHistoric = query =>
	api.get('/supplies/discard-allocation', { params: query });

export const deleteDiscard = id =>
	api.delete(`/supplies/discard-allocation/${id}`);

export const getList = query =>
	api.get(`/supplies/discard-allocation/do/list`, { params: query });

export const newDiscard = discard =>
	api.post('/supplies/discard-allocation/do/new', discard);
