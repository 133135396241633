import * as types from './types';


export const uploadFile = file => {
	return {
		type: types.UPLOAD_FILE,
		file,
	};
};

export const setTablePrice = (body, headers, uuid) => {
	return {
		type: types.SET_PRICE_TABLE,
		body,
		headers,
		uuid,
	};
};

export const newUpdate = () => {
  return {
		type: types.NEW_UPDATE
	};
}

export const modalLog = () => {
  return {
		type: types.MODAL_LOG
	};
}

export const setLog = logs => {
  return {
		type: types.SET_LOGS,
		logs
	};
}

export default {
  uploadFile,
  setTablePrice,
	newUpdate,
	modalLog,
	setLog
}