export const GET_LIST_COMMERCIAL = 'commercial-description/GET_LIST_COMMERCIAL';
export const SET_LIST_COMMERCIAL = 'commercial-description/SET_LIST_COMMERCIAL';
export const DELETE_COMMERCIAL = 'commercial-description/DELETE_COMMERCIAL';
export const NEW_COMMERCIAL = 'commercial-description/NEW_COMMERCIAL';
export const SELECT_COMMERCIAL = 'commercial-descriptiont/SELECT_COMMERCIAL';
export const MODAL_COMMERCIAL = 'commercial-description/MODAL_COMMERCIAL';
export const SET_ITEMS_COMMERCIAL =
	'commercial-description/SET_ITEMS_COMMERCIAL';
export const EDIT_ITEM_COMMERCIAL =
	'commercial-description/EDIT_ITEM_COMMERCIAL';
export const ADD_ITEM_COMMERCIAL = 'commercial-description/ADD_ITEM_COMMERCIAL';
export const EDIT_COMMERCIAL = 'commercial-description/EDIT_COMMERCIAL';
export const DELETE_ITEM_COMMERCIAL =
	'commercial-description/DELETE_ITEM_COMMERCIAL';
export const RESET_ITEMS_COMMERCIAL =
	'commercial-description/RESET_ITEMS_COMMERCIAL';

export default {
	GET_LIST_COMMERCIAL,
	SET_LIST_COMMERCIAL,
	DELETE_COMMERCIAL,
	NEW_COMMERCIAL,
	SELECT_COMMERCIAL,
	MODAL_COMMERCIAL,
	SET_ITEMS_COMMERCIAL,
	EDIT_ITEM_COMMERCIAL,
	ADD_ITEM_COMMERCIAL,
	EDIT_COMMERCIAL,
	DELETE_ITEM_COMMERCIAL,
	RESET_ITEMS_COMMERCIAL,
};
