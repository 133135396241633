import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as additionMotivesApi from '../../../api/appApi/additionMotives';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(additionMotivesApi.get, query);

		yield put(actions.setAdditionMotives(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Motivos para justificativa.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newAdditionMotives(payload) {
	const { addition } = payload;

	try {
		const response = yield call(additionMotivesApi.newAddition, addition);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Motivo para justificativa cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/cost/addition-motives');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar motivo para justificativa.',
				'error',
			),
		);
	}
}

export function* editAdditionMotives(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.addition;

	const { id } = payload;

	try {
		const response = yield call(additionMotivesApi.editAddition, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Motivo editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/cost/addition-motives');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Motivos para justificativa.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteAddition(payload) {
	const { addition } = payload;
	try {
		const response = yield call(additionMotivesApi.deleteAddition, addition);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Motivo para justificativa deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getAdditionMotives(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Motivo para justificativa.',
				'error',
			),
		);
	}
}

export default function* watchAdditionMotives() {
	yield takeLatest(types.GET_ADDITION_MOTIVES, get);
	yield takeLatest(types.ADD_ADDITION_MOTIVES, newAdditionMotives);
	yield takeLatest(types.EDIT_ADDITION_MOTIVES, editAdditionMotives);
	yield takeLatest(types.DELETE_ADDITION_MOTIVES, deleteAddition);
}
