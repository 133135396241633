export const SET_GROUP_LINE_LIST = 'item-group-line/SET_GROUP_LINE_LIST';
export const GET_GROUP_LINE_LIST = 'item-group-line/GET_GROUP_LINE_LIST';
export const SET_GROUP_LINE = 'item-group-line/SET_GROUP_LINE';
export const GET_GROUP_LINE = 'item-group-line/GET_GROUP_LINE';
export const ADD_GROUP_LINE = 'item-group-line/ADD_GROUP_LINE';
export const EDIT_GROUP_LINE = 'item-group-line/EDIT_GROUP_LINE';
export const DELETE_GROUP_LINE = 'item-group-line/DELETE_GROUP_LINE';

export default {
	SET_GROUP_LINE_LIST,
	GET_GROUP_LINE_LIST,
	SET_GROUP_LINE,
	GET_GROUP_LINE,
	ADD_GROUP_LINE,
	EDIT_GROUP_LINE,
	DELETE_GROUP_LINE,
};
