import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as groupItensApi from '../../../api/appApi/groupItens';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(groupItensApi.get, query);

		yield put(actions.setGroupItens(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Grupo de Itens.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newGroupItens(payload) {
	const { item } = payload;

	item.status = true;

	try {
		const response = yield call(groupItensApi.insert, item);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo de Itens cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/group-itens');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Grupo de Itens.',
				'error',
			),
		);
	}
}

export function* editGroupItens(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.item;

	const { id } = payload;

	try {
		const response = yield call(groupItensApi.update, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo de Itens editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/group-itens');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Grupo de Itens.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteGroupItens(payload) {
	const { id } = payload;

	try {
		const response = yield call(groupItensApi.remove, id);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Grupo de Itens deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getGroupItens(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Grupo de Itens.',
				'error',
			),
		);
	}
}

export default function* watchGroupItens() {
	yield takeLatest(types.GET_GROUP_ITENS, get);
	yield takeLatest(types.ADD_GROUP_ITENS, newGroupItens);
	yield takeLatest(types.EDIT_GROUP_ITENS, editGroupItens);
	yield takeLatest(types.DELETE_GROUP_ITENS, deleteGroupItens);
}
