import api from './api';

export const get = query =>
	api.get('/supplies/feature', { params: query });

export const getById = id => api.get(`/supplies/feature/${id}`);

export const deleteFeature = id => api.delete(`/supplies/feature/${id}`);

export const newFeature = feature => api.post('/supplies/feature', feature);

export const editFeature = (feature, id) =>
	api.put(`/supplies/feature/${id}`, feature);
