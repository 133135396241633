import { put, takeLatest, call, select } from 'redux-saga/effects';
import { notificationActions } from '../notification';
import * as types from './types';
import * as updateTable from '../../../api/appApi/updateTable'
import * as actions from './actions';
import { apiActions } from '../api';
import * as selectors from './selectors';


export function* updateFile(payload) {
	const { file } = payload;


	try {
		const data = new FormData();
		data.append('import', file.file, file.name);

		const response = yield call(updateTable.uploadFile, data);

		const headers = [];

		const width = 100 / response.data.data.headers.length;
		response.data.data.headers.forEach(h => {
			headers.push({
				name: h.display,
				selector: h.field,
				width: `${width}%`,
				sortable: true,
			});
		});

		yield put(
			actions.setTablePrice(
				response.data.data.body,
				headers,
				response.data.data.uuid,
			),
		);
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao fazer upload do arquivo.',
				'error',
			),
		);
	}

}

export function* newUpload(payload) {
	yield put(apiActions.apiSubmitStart());


	const uuid = yield select(selectors.getTable);

	if (!uuid) {
		yield put(
			notificationActions.addNotification(
				'Faça a importação de uma tabela de preço!',
				'error',
			),
		);
	} else {
		try {
			const importResponse = yield call(updateTable.importTable, { uuid });
			yield put(actions.setLog(importResponse.data.data.responseLog.response))
			if (importResponse.status) {
				yield put(actions.modalLog());

			}

		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao importar tabela de preço.',
					'error',
				),
			);
		}
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchUploadTable() {
	yield takeLatest(types.UPLOAD_FILE, updateFile);
	yield takeLatest(types.NEW_UPDATE, newUpload);

}