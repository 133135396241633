export const GET_LIST_TIPOS = 'cad-tipes/GET_LIST_TIPOS';
export const SET_LIST_TIPOS = 'cad-tipes/SET_LIST_TIPOS';
export const DELETE_TIPO = 'cad-tipes/DELETE_TIPO';
export const NEW_TYPE = 'cad-tipes/NEW_TIPO';
export const SELECT_TIPOS= 'cad-tipes/SELECT_TIPOS';
export const MODAL_BUDGET = 'advertising-budget/MODAL_BUDGET';
export const SET_ITEMS_TIPOS = 'cad-tipes/SET_ITEMS_TIPOS';
export const EDIT_ITEM_TIPOS = 'cad-tipes/EDIT_ITEM_TIPOS';
export const ADD_ITEM_BUDGET = 'advertising-budget/ADD_ITEM_BUDGET';
export const EDIT_TIPOS = 'cad-tipes/EDIT_TIPOS';
export const DELETE_ITEM_BUDGET = 'advertising-budget/DELETE_ITEM_BUDGET';
export const RESET_ITEMS_BUDGET = 'advertising-budget/RESET_ITEMS_BUDGET';

export default {
	GET_LIST_TIPOS,
	SET_LIST_TIPOS,
	DELETE_TIPO,
	NEW_TYPE,
	SELECT_TIPOS,
	MODAL_BUDGET,
	SET_ITEMS_TIPOS,
	EDIT_ITEM_TIPOS,
	ADD_ITEM_BUDGET,
	EDIT_TIPOS,
	DELETE_ITEM_BUDGET,
	RESET_ITEMS_BUDGET,
};
