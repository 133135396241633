import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as featurePerGroup from '../../../api/appApi/featurePerGroup';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(featurePerGroup.get, query);

		yield put(actions.setListFeaturePerGroup(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Caracteristica por grupo.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getGroup(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(featurePerGroup.getGroup, query);

		yield put(actions.setGroup(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Caracteristica por grupo.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}


export function* deleteFeaturePerGroup(payload) {
	const { feature } = payload;

	try {
		const response = yield call(featurePerGroup.remove, feature);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Caracteristica por grupo deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getListFeaturePerGroup(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Caracteristica por grupo.',
				'error',
			),
		);
	}
}

export function* newFeaturePerGroup(payload) {
	const { feature } = payload;

	try {
		const response = yield call(featurePerGroup.add, feature);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Caracteristica por grupo cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/supplies/cad/feature-per-group');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Caracteristica por grupo.',
				'error',
			),
		);
	}
}

export function* editFeaturePerGroup(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.feature;

	const { id } = payload;

	try {
		const response = yield call(featurePerGroup.edit, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Caracteristica por grupo editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/supplies/cad/feature-per-group');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Caracteristica por grupo.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export default function* watchFeaturePerGroup() {
	yield takeLatest(types.GET_FEATURE_PER_GROUP_LIST, get);
	yield takeLatest(types.DELETE_FEATURE_PER_GROUP, deleteFeaturePerGroup);
	yield takeLatest(types.ADD_FEATURE_PER_GROUP, newFeaturePerGroup);
	yield takeLatest(types.EDIT_FEATURE_PER_GROUP, editFeaturePerGroup);
	yield takeLatest(types.GET_GROUP, getGroup);
}
