import * as types from './types';


export const getSisdevin = query => ({
	type: types.GET_SISDEVIN,
	query,
});

export const setSisdevin = sisdevin => ({
	type: types.SET_SISDEVIN,
	sisdevin,
});

export const setSelectCompositionSisdevin = sisdevin => ({
	type: types.SELECT_SISDEVIN,
	sisdevin,
});

export const addSisdevin = sisdevin => ({
	type: types.ADD_SISDEVIN,
	sisdevin,
});

export const editSisdevin = (sisdevin, id) => ({
	type: types.EDIT_SISDEVIN,
	sisdevin,
	id,
});

export const deleteSisdevin = sisdevin => ({
	type: types.DELETE_SISDEVIN,
	sisdevin,
});

export default {
	getSisdevin,
	setSisdevin,
	addSisdevin,
	editSisdevin,
	deleteSisdevin,
	setSelectCompositionSisdevin
};
