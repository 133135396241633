import React from 'react';
import PropTypes from '../../../../lib/utils/propTypes';

const RowHeadTable = ({ row, index, ...restProps }) => {
	//console.log(row)
	return (
		<div {...restProps} style={{ width: '100%' }}>
			<table style={{ width: '100%' }}>
				<tbody>
					<tr>
						<td width="25%">{row.values[index].value}</td>
						<td width="25%">{row.values[index].st}</td>
						<td width="25%">{row.values[index].ipi}</td>
						<td width="25%">{row.values[index].unit}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

RowHeadTable.propTypes = {
	row: PropTypes.shape({ values: PropTypes.array }).isRequired,
	index: PropTypes.number.isRequired,
};

export default RowHeadTable;
