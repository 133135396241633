import * as types from './types';

export const getClassifications = (data) => ({
	type: types.GET_CLASSIFICATIONS,
	data
});

export const setClassifications = classifications => ({
	type: types.SET_CLASSIFICATIONS,
	classifications,
});

export const getItensByClassification = query => ({
	type: types.GET_ITENS_CLASSIFICATION,
	query,
});

export const setItens = itens => ({
	type: types.SET_ITENS,
	itens,
});

export const getSuppliers = query => ({
	type: types.GET_SUPPLIERS,
	query,
});

export const setSuppliers = suppliers => ({
	type: types.SET_SUPPLIERS,
	suppliers,
});

export const setItensByStock = itens => ({
	type: types.SET_ITENS_STOCK,
	itens,
});

export const getItensByStock = query => ({
	type: types.GET_ITENS_STOCK,
	query,
});

export const getItens = query => ({
	type: types.GET_ITENS,
	query,
});

export const getItensUni = query => ({
	type: types.GET_ITENS_UNI,
	query,
});

export const setItem = itens => ({
	type: types.SET_ITEM,
	itens,
});

export const setItemUni = itens => ({
	type: types.SET_ITENS_UNI,
	itens,
});

export const setLine = lines => ({
	type: types.SET_LINE,
	lines,
});

export const getLine = () => ({
	type: types.GET_LINE,
});

export const setUfs = ufs => ({
	type: types.SET_UFS,
	ufs,
});

export const getUfs = () => ({
	type: types.GET_UFS,
});

export const setSegments = segments => ({
	type: types.SET_SEGMENTS,
	segments,
});

export const getSegments = () => ({
	type: types.GET_SEGMENTS,
});

export const setSalesTable = sales => ({
	type: types.SET_SALES_TABLE,
	sales,
});

export const getSalesTable = () => ({
	type: types.GET_SALES_TABLE,
});

export const setEnterprises = enterprises => ({
	type: types.SET_ENTERPRISES,
	enterprises,
});

export const getEnterprises = () => ({
	type: types.GET_ENTERPRISES,
});



export default {
	getClassifications,
	setClassifications,
	getItensByClassification,
	setItens,
	getSuppliers,
	setSuppliers,
	setItensByStock,
	getItensByStock,
	setLine,
	getLine,
	setUfs,
	getUfs,
	setSegments,
	getSegments,
	setSalesTable,
	getSalesTable,
	getItens,
	setItem,
	setItemUni,
	getItensUni,
	setEnterprises,
	getEnterprises
};
