import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as descriptionItemApi from '../../../api/appApi/descriptionItem';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(descriptionItemApi.get, query);

		yield put(actions.setDescriptionItem(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao Buscar Itens.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getAllItens(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(descriptionItemApi.getAllItens, query);

		console.log('oi')
		yield put(actions.setAllItem(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao Buscar Itens.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newDescriptionItem(payload) {
	const { item } = payload;

	item.status = true;

	try {
		const response = yield call(descriptionItemApi.insert, item);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Item cadastrado com Sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/descricao-item');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Item.',
				'error',
			),
		);
	}
}

export function* editDescriptionItem(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.item;

	const { id } = payload;

	try {
		const response = yield call(descriptionItemApi.update, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Item editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/descricao-item');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Item.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteDescriptionItem(payload) {
	const { item } = payload;

	try {
		const response = yield call(descriptionItemApi.remove, item);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Item deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getDescriptionItem(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Item.',
				'error',
			),
		);
	}
}

export default function* watchDescriptionItem() {
	yield takeLatest(types.GET_DESCRIPTION_ITEM, get);
	yield takeLatest(types.ADD_DESCRIPTION_ITEM, newDescriptionItem);
	yield takeLatest(types.EDIT_DESCRIPTION_ITEM, editDescriptionItem);
	yield takeLatest(types.DELETE_DESCRIPTION_ITEM, deleteDescriptionItem);
	yield takeLatest(types.GET_ALL_ITEMS, getAllItens);
}
