import {
	MdBuild,
	MdDashboard,
	MdLocalShipping,
	MdAttachMoney,
	MdList,
	MdOutlineInventory2,
	MdScience,
	MdVerifiedUser,
	MdSearch,
} from 'react-icons/md';

import { VscSettingsGear } from 'react-icons/vsc';

export const navItems = [
	{
		to: '/',
		name: 'Dashboard',
		exact: true,
		Icon: MdDashboard,
	},
];

export const navAux = [
	{
		to: '/usuarios',
		name: 'Usuários',
		exact: false,
		id: 291,
	},
	{
		to: '/grupos',
		name: 'Grupos',
		exact: false,
		id: 292,
	},
	{
		to: '/cadastro-tipos',
		name: 'Cadastro de Tipos',
		exact: false,
		id: 293,
	},
];

export const navParams = [
	{
		to: '/atualizacao-tabela-vendas',
		isModule: false,
		name: 'Atualização De Tabela De Venda',
		exact: false,
		id: 305,
	},
	{
		to: '/comercial/cad/',
		isModule: true,
		name: 'Cadastros-com',
		desc: 'Cadastros',
		exact: false,
		id: 307,
		Icon: MdList,
		submodules: [
			{
				to: '/descricao-comercial',
				name: 'Descrição Comercial',
				exact: false,
				id: 237,
			},
			{
				to: '/grupos-linhas-produto',
				name: 'Grupo das Linhas de Produtos',
				exact: false,
				id: 238,
			},
			{
				to: '/grupos-tabelas-precos',
				name: 'Grupo das Tabelas de Vendas',
				exact: false,
				id: 239,
			},
			{
				to: '/comercia/cad/discount',
				name: 'Motivos de Descontos',
				exact: false,
				id: 232,
			},
			{
				to: '/commercial/cad/product-line',
				name: 'Linhas de Produtos',
				exact: false,
				id: 322,
			},
		],
	},
	{
		to: '/comercial/cons/',
		isModule: true,
		name: 'Consultas-com',
		desc: 'Consultas',
		exact: false,
		id: 307,
		Icon: MdSearch,
		submodules: [
			{
				to: '/comercia/cons/tech-data',
				name: 'Dados Técnicos',
				exact: false,
				id: 245,
			},
			{
				to: '/tabela-vendas',
				name: 'Tabela De Vendas',
				exact: false,
				id: 244,
			},
			{
				to: '/grupos-linhas-produto',
				name: 'Grupo das Linhas de Produtos',
				exact: false,
				id: 238,
			},
			{
				to: '/grupos-tabelas-precos',
				name: 'Grupo das Tabelas de Vendas',
				exact: false,
				id: 239,
			},
			{
				to: '/comercia/cad/discount',
				name: 'Motivos de Descontos',
				exact: false,
				id: 232,
			},
		],
	},
];

export const navSup = [
	{
		to: '/programacao-producao',
		name: 'Programação de Materiais',
		exact: false,
		id: 264,
	},
	{
		to: '/sup/cad/',
		isModule: true,
		name: 'Cadastros-sup',
		desc: 'Cadastros',
		exact: false,
		id: 219,
		Icon: MdList,
		submodules: [
			{
				to: '/descricao-item',
				name: 'Descrição Interna de Itens',
				order: 6,
				exact: false,
				id: 315,
			},
			{
				to: '/forn',
				name: 'Fornecedor Preferencial',
				order: 7,
				exact: false,
				id: 265,
			},
			{
				to: '/sup/cad/item-feature',
				name: 'Características do Item',
				order: 5,
				exact: false,
				id: 246,
			},
			{
				to: '/sup/cad/feature',
				name: 'Características',
				order: 2,
				exact: false,
				id: 234,
			},
			{
				to: '/sup/cad/feature-response',
				name: 'Respostas de Características',
				order: 4,
				exact: false,
				id: 236,
			},
			{
				to: '/sup/cad/item',
				name: 'Item Caixa x Item Unidade',
				order: 7,
				exact: false,
				id: 275,
			},
			{
				to: '/sup/cad/group-itens',
				name: 'Grupos de Itens',
				exact: false,
				order: 1,
				id: 316,
			},
			{
				to: '/supplies/cad/feature-per-group',
				name: 'Características por Grupo',
				order: 3,
				exact: false,
				id: 323,
			},
		],
	},
	// {
	// 	to: '/sup/cons/',
	// 	isModule: true,
	// 	name: 'Consultas-sup',
	// 	desc: 'Consultas',
	// 	exact: false,
	// 	id: 220,
	// 	Icon: MdSearch,
	// 	submodules: []
	// },
];

export const navLog = [
	{
		to: '/log/fre-divergence',
		name: 'Divergência de Fretes',
		exact: false,
		id: 335,
	},
	{
		to: '/log/cad/',
		isModule: true,
		name: 'Cadastros-log',
		desc: 'Cadastros',
		exact: false,
		id: 334,
		Icon: MdList,
		submodules: [
			{
				to: '/cost/addition-motives',
				name: 'Justificativa de Divergência de Frete',
				order: 1,
				exact: false,
				id: 333,
			},
		]
	}
];

export const navCosts = [
	{
		to: '/cost/cad/',
		isModule: true,
		name: 'Cadastros-cst',
		desc: 'Cadastros',
		exact: false,
		id: 213,
		Icon: MdList,
		submodules: [
			{
				to: '/verbas-publicitarias',
				name: 'Verbas Publicitárias',
				exact: false,
				id: 249,
			},
			{
				to: '/despesas-vendas',
				name: 'Despesas De Venda',
				exact: false,
				id: 250,
			},
			,
			{
				to: '/cadastro-tipos',
				name: 'Tipos de Despesa de Venda',
				exact: false,
				id: 251,
			},
		],
	},
	// {
	// 	to: '/cost/cons/',
	// 	isModule: true,
	// 	name: 'Consultas-cst',
	// 	desc: 'Consultas',
	// 	exact: false,
	// 	id: 214,
	// 	Icon: MdSearch,
	// 	submodules: [

	// 	]
	// },
];

export const navStock = [
	 {
	 	to: '/stock/cad/',
	 	isModule: true,
	 	name: 'Cadastros-est',
	 	desc: 'Cadastros',
	 	exact: false,
	 	id: 215,
	 	Icon: MdList,
	 	submodules: [
			{
				to: '/stock/transfer-motives',
				name: 'Motivos de Transferência',
				exact: false,
				id: 329,
			},
		]
	},
	{
		to: '/stock/cons/',
		isModule: true,
		name: 'Consultas-est',
		desc: 'Consultas',
		exact: false,
		id: 216,
		Icon: MdSearch,
		submodules: [
			{
				to: '/stock/cons/stock-sit',
				name: 'Situação de Estoque',
				exact: false,
				id: 259,
			},
			{
				to: '/stock/cons/item-sit',
				name: 'Situação de Item',
				exact: false,
				id: 260,
			},
			{
				to: '/stock/cons/almox-sit',
				name: 'Situação de Almoxarifado',
				exact: false,
				id: 261,
			},
		],
	},
];

export const navLab = [
	{
		to: '/lab/cad/',
		isModule: true,
		name: 'Cadastros-lab',
		desc: 'Cadastros',
		exact: false,
		id: 217,
		Icon: MdList,
		submodules: [
			{
				to: '/laboratory/cad/composition-feature',
				name: 'Características de Composição',
				exact: false,
				id: 263,
			},
			{
				to: '/laboratory/cad/sisdevin',
				name: 'Classificação Sisdevin',
				exact: false,
				id: 324,
			},
		],
	},
	// {
	// 	to: '/lab/cons/',
	// 	isModule: true,
	// 	name: 'Consultas-lab',
	// 	desc: 'Consultas',
	// 	exact: false,
	// 	id: 218,
	// 	Icon: MdSearch,
	// 	submodules: []
	// },
];

export const navQualid = [
	{
		to: '/qualid/cad/insp-rec',
		name: 'Inspeção de Recebimento',
		exact: false,
		order: 1,
		id: 271,
	},
	{
		to: '/qualid/cad/',
		isModule: true,
		name: 'Cadastros-qua',
		desc: 'Cadastros',
		exact: false,
		order: 2,
		id: 223,
		Icon: MdList,
		submodules: [
			{
				to: '/qualid/cad/tech-esp',
				name: 'Especificações Técnicas',
				exact: false,
				id: 273,
			},
			{
				to: '/datasheet',
				name: 'Ficha Técnica',
				exact: false,
				id: 274,
			},
			{
				to: '/qualid/cad/specification',
				name: 'Parâmetros de Especificação',
				exact: false,
				id: 318,
			},
			{
				to: '/qualid/cad/parameters-response',
				name: 'Respostas de Parâmetros',
				exact: false,
				id: 319,
			},
			{
				to: '/qualid/cad/params-for-group',
				name: 'Parâmetros por Grupo',
				exact: false,
				id: 320,
			},
		],
	},
	// {
	// 	to: '/qualid/cons/',
	// 	isModule: true,
	// 	name: 'Consultas-qua',
	// 	desc: 'Consultas',
	// 	exact: false,
	// 	id: 224,
	// 	Icon: MdSearch,
	// 	submodules: []
	// },
];

export const routes = [
	{
		name: 'Auxiliares',
		icon: MdBuild,
		order: 7,
		submodules: navAux,
	},
	{
		name: 'Suprimentos',
		icon: MdLocalShipping,
		order: 5,
		submodules: navSup,
	},
	{
		name: 'Logística',
		icon: MdLocalShipping,
		order: 8,
		submodules: navLog,
	},
	{
		name: 'Custos',
		icon: MdAttachMoney,
		order: 2,
		submodules: navCosts,
	},
	{
		name: 'Comercial',
		icon: MdAttachMoney,
		order: 1,
		submodules: navParams,
	},
	{
		name: 'Estoques',
		icon: MdOutlineInventory2,
		order: 3,
		submodules: navStock,
	},
	{
		name: 'Laboratório',
		icon: MdScience,
		order: 4,
		submodules: navLab,
	},
	{
		name: 'Qualidade',
		icon: MdVerifiedUser,
		order: 6,
		submodules: navQualid,
	},
];
