import api from './api';

export const get = query => api.get('/supplies/group-itens', { params: query });

export const remove = id => api.delete(`/supplies/group-itens/${id}`);

export const insert = item =>
	api.post('/supplies/group-itens', item);

export const update = (item, id) =>
	api.put(`/supplies/group-itens/${id}`, item);
