export const GET_PARAMETERS_RESPONSE =
	'parameters-response/GET_PARAMETERS_RESPONSE';
export const SET_PARAMETERS_RESPONSE =
	'parameters-response/SET_PARAMETERS_RESPONSE';
export const ADD_PARAMETERS_RESPONSE =
	'parameters-response/ADD_PARAMETERS_RESPONSE';
export const EDIT_PARAMETERS_RESPONSE =
	'parameters-response/EDIT_PARAMETERS_RESPONSE';
export const DELETE_PARAMETERS_RESPONSE =
	'parameters-response/DELETE_PARAMETERS_RESPONSE';
export const SET_SELECT_PARAMETERS_RESPONSE =
	'parameters-response/SET_SELECT_PARAMETERS_RESPONSE';
export const GET_GROUP = 'feature-per-group/GET_GROUP';
export const SET_GROUP = 'feature-per-group/SET_GROUP';

export default {
	GET_PARAMETERS_RESPONSE,
	SET_PARAMETERS_RESPONSE,
	ADD_PARAMETERS_RESPONSE,
	EDIT_PARAMETERS_RESPONSE,
	DELETE_PARAMETERS_RESPONSE,
	SET_SELECT_PARAMETERS_RESPONSE,
	GET_GROUP,
	SET_GROUP
};
