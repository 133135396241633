import * as types from './types';

export const getDatasheet = query => ({
	type: types.GET_DATASHEET,
	query,
});

export const setDatasheet = datasheet => ({
	type: types.SET_DATASHEET,
	datasheet
});

export const addDatasheet = datasheet => ({
	type: types.ADD_DATASHEET,
	datasheet
});

export const editDatasheet = (datasheet, id) => ({
	type: types.EDIT_DATASHEET,
	datasheet,
	id
});

export const selectDatasheet = datasheet => ({
	type: types.SELECT_DATASHEET,
	datasheet
});

export const deleteDatasheet = id => ({
	type: types.DELETE_DATASHEET,
    id
});

export const getMaterial = query => ({
	type: types.GET_MATERIAL,
	query,
});

export const setMaterial = material => ({
	type: types.SET_MATERIAL,
	material,
});

export const addMaterial = material => ({
	type: types.ADD_MATERIAL,
	material,
});

export const selecteMateria = material => ({
	type: types.SELECT_MATERIAL,
	material,
});

export const getStrucMaterial = material => ({
	type: types.GET_STRUCT_MATERIAL,
	material,
});

export const modalImg = img => ({
	type: types.MODAL_IMG,
    img,
})


export default {
	getDatasheet,
	setDatasheet,
	addDatasheet,
	getMaterial,
	setMaterial,
	addMaterial,
	selecteMateria,
	getStrucMaterial,
	editDatasheet,
	selectDatasheet,
	deleteDatasheet,
	modalImg
};
