import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import PulseLoader from 'react-spinners/PulseLoader';
import bn from '../../../lib/utils/bemnames';
import { navItems } from '../../../routes/menu';
import R from '../../../lib/constants/R';
import SidebarPage from './SidebarPages';
import SourceLink from '../SourceLink';
import PropTypes from '../../../lib/utils/propTypes';

const sidebarBackground = {
	// backgroundImage: `url("${R.images.sidebar}")`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenSistema: false,
		};
	}

	handleClick = name => () => {
		this.setState(prevState => {
			const isOpen = prevState[`isOpen${name}`];

			return {
				[`isOpen${name}`]: !isOpen,
			};
		});
	};

	modulePermission = module => {
		const { permissionsMenu, modules } = this.props;

		function compareNumbers(a, b) {
			return a.order - b.order;
		}

		module.map(item => {
			item.submodules.sort(compareNumbers);
			return item;
		});

		module.sort(function(a, b) {
			return a.route.order - b.route.order;
		});

		return module.map(item => {
			return (
				<SidebarPage
					key={item.route.name}
					// eslint-disable-next-line react/destructuring-assignment
					isOpen={this.state[`isOpen${item.route.name}`]}
					namePages={item.route.name}
					click={name => this.handleClick(name)}
					pageContents={item.submodules}
					IconPage={item.route.icon}
					modules={modules}
				/>
			);
		});
	};

	render() {
		const { modules, permissionsMenu } = this.props;
		return (
			<aside className={bem.b()} data-image={R.images.sidebar}>
				<div
					className={bem.e('background')}
					style={sidebarBackground}
				/>

				<div className={bem.e('content')}>
					<Navbar styles={{marginBottom: 20}}>
						<SourceLink className="navbar-brand nav-logo d-flex">
							<img src={R.images.logo_white} height="30" alt="" />
						</SourceLink>
					</Navbar>
					{modules.length > 0 ? (
						<div>
							<Nav vertical>
								{navItems.map(
									({ to, name, exact, Icon }, index) => (
										<NavItem
											key={name}
											className={bem.e('nav-item')}>
											<BSNavLink
												id={`navItem-${name}-${index}`}
												tag={NavLink}
												to={to}
												activeClassName="active"
												exact={exact}>
												<Icon
													className={bem.e(
														'nav-item-icon',
													)}
												/>
												<span className="nav-name">
													{name}
												</span>
											</BSNavLink>
										</NavItem>
									),
								)}
								{this.modulePermission(permissionsMenu)}
							</Nav>
						</div>
					) : (
						<div align="center" className="mt-4">
							<PulseLoader color="#fff" />
						</div>
					)}
				</div>
			</aside>
		);
	}
}

const mapStateToProps = state => {
	return {
		modules: state.enterprise.modulesCompany,
		permissionsMenu: state.enterprise.permissionsMenu,
	};
};

const mapDispatchToProps = () => {
	return {};
};

Sidebar.propTypes = {
	permissionsMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
	modules: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
