import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as itemBoxUnityApi from '../../../api/appApi/itemBoxUnity';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(itemBoxUnityApi.get, query);

		yield put(actions.setItemBoxUnity(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Item.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newItemBoxUnity(payload) {
	const { item } = payload;

	item.status = true;

	try {
		const response = yield call(
			itemBoxUnityApi.newItemBoxUnity,
			item,
		);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Item cadastrada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/item');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Item.',
				'error',
			),
		);
	}
}

export function* editItemBoxUnity(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.item;

	const { id } = payload;

	try {
		const response = yield call(itemBoxUnityApi.editItemBoxUnity, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Item editada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/sup/cad/item');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar Item.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteItemBoxUnity(payload) {
	const { item } = payload;

	try {
		const response = yield call(
			itemBoxUnityApi.deleteItemBoxUnity,
			item,
		);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Item deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getItemBoxUnity(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Item.',
				'error',
			),
		);
	}
}

export default function* watchItemBoxUnity() {
	yield takeLatest(types.GET_ITEM_BOX_UNITY, get);
	yield takeLatest(types.ADD_ITEM_BOX_UNITY, newItemBoxUnity);
	yield takeLatest(types.EDIT_ITEM_BOX_UNITY, editItemBoxUnity);
	yield takeLatest(types.DELETE_ITEM_BOX_UNITY, deleteItemBoxUnity);
}
