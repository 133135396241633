import * as types from './types';


export const getCompositionFeature = query => ({
	type: types.GET_COMPOSITION_FEATURE,
	query,
});

export const setCompositionFeature = composition => ({
	type: types.SET_COMPOSITION_FEATURE,
	composition,
});

export const addCompositionFeature = composition => ({
	type: types.ADD_COMPOSITION_FEATURE,
	composition,
});

export const editCompositionFeature = (composition, id) => ({
	type: types.EDIT_COMPOSITION_FEATURE,
	composition,
	id,
});

export const deleteCompositionFeature = composition => ({
	type: types.DELETE_COMPOSITION_FEATURE,
	composition,
});

export const setSelectCompositionFeature = composition => ({
	type: types.SET_SELECT_COMPOSITION_FEATURE,
	composition,
});

export const getItens = itens => ({
	type: types.GET_ITENS,
	itens,
});

export const setItens = itens => ({
	type: types.SET_ITENS,
	itens,
});

export default {
	getCompositionFeature,
	setCompositionFeature,
	addCompositionFeature,
	editCompositionFeature,
	deleteCompositionFeature,
	setSelectCompositionFeature,
	getItens,
	setItens
};
