import api from './api';

export const get = query =>
    api.get('/supplies/feature-per-group', { params: query });

export const getGroup = query =>
    api.get('/supplies/feature-per-group/group', { params: query });

export const remove = id => api.delete(`/supplies/feature-per-group/${id}`);

export const add = params => api.post('/supplies/feature-per-group', params);

export const edit = (params, id) =>
    api.put(`/supplies/feature-per-group/${id}`, params);
