import { SET_REPORT_LIST, SET_REPORT_COLUMNS } from './types';

const initialState = {
	list: [],
	columns: [],
};

const costReportReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_REPORT_LIST:
			return {
				...state,
				list: action.list,
			};
		case SET_REPORT_COLUMNS:
			return {
				...state,
				columns: action.columns,
			};
		default:
			return state;
	}
};

export default costReportReducer;
