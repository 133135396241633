import {
	SET_FRE_DIVERGENCE,
	SELECT_FRE_DIVERGENCE,
	MODAL_BUDGET
} from './types';

const initialState = {
	divergence: [],
	modal: false,
};

const freDivergenceReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FRE_DIVERGENCE:
			return {
				...state,
				divergence: action.divergence,
			};
		case SELECT_FRE_DIVERGENCE:
			return {
				...state,
				modal: !state.modal,
			};
		case MODAL_BUDGET:
			return {
				...state,
				modal: !state.modal,
			};
		default:
			return state;
	}
};

export default freDivergenceReducer;
