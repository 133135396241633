import api from './api';

export const get = query =>
	api.get('/cost/addition-motives', { params: query });

export const deleteAddition = id => api.delete(`/cost/addition-motives/${id}`);

export const newAddition = addition => api.post('/cost/addition-motives', addition);

export const editAddition = (addition, id) =>
	api.put(`/cost/addition-motives/${id}`, addition);
