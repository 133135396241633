import {
	SET_PRICE_TABLE,
	MODAL_LOG,
	SET_LOGS,
} from './types';

const initialState = {
	body: [],
	columns: [],
	uuid: false,
	modalLog: false,
	logs: []
};

const updateTableReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PRICE_TABLE:
			return {
				...state,
				body: action.body,
				columns: action.headers,
				uuid: action.uuid,
			};
		case MODAL_LOG:
			return{
				...state,
				modalLog: true
			}
		case SET_LOGS:
			return {
				...state,
				logs: action.logs
			}
		default:
			return state;
	}
};

export default updateTableReducer;