const SET_LIST_USERS = 'users/SET_LIST_USERS';
const ADD_USER = 'users/ADD_USER';
const GET_USER = 'users/GET_USER';
const SET_USER = 'users/SET_USER';
const GET_LIST_USERS = 'users/GET_LIST_USERS';
const EDIT_USER = 'users/EDIT_USER';
const GET_USER_PERMISSION = 'users/GET_USER_PERMISSION';
const SET_USER_PERMISSION = 'users/SET_USER_PERMISSION';
const ACTIVE_DESACTIVE_USER = 'users/ACTIVE_DESACTIVE_USER';
const SET_USER_REFRESH = 'users/SET_USER_REFRESH';
const UPDATE_USER_PERMISSION = 'users/UPDATE_USER_PERMISSION';
const CHANGE_PASSWORD = 'users/CHANGE_PASSWORD';
const EDIT_USER_PROFILE = 'users/EDIT_USER_PROFILE';
const ADD_IMG_PROFILE = 'users/ADD_IMG_PROFILE';
const SET_IMG_PROFILE = 'users/SET_IMG_PROFILE';
const GET_IMG_PROFILE = 'users/GET_IMG_PROFILE';

export {
	SET_LIST_USERS,
	ADD_USER,
	GET_USER,
	SET_USER,
	GET_LIST_USERS,
	EDIT_USER,
	GET_USER_PERMISSION,
	SET_USER_PERMISSION,
	ACTIVE_DESACTIVE_USER,
	SET_USER_REFRESH,
	UPDATE_USER_PERMISSION,
	CHANGE_PASSWORD,
	EDIT_USER_PROFILE,
	ADD_IMG_PROFILE,
	SET_IMG_PROFILE,
	GET_IMG_PROFILE,
};
