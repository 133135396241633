import api from './api';

export const get = query =>
	api.get('/quality/params-spec', { params: query });

export const getById = id => api.get(`/quality/params-spec/${id}`);

export const remove = id => api.delete(`/quality/params-spec/${id}`);

export const insert = feature => api.post('/quality/params-spec', feature);

export const update = (feature, id) =>
	api.put(`/quality/params-spec/${id}`, feature);
