import * as types from './types';

export const getListFeaturePerGroup = query => ({
	type: types.GET_FEATURE_PER_GROUP_LIST,
	query,
});

export const setListFeaturePerGroup = feature => ({
	type: types.SET_FEATURE_PER_GROUP_LIST,
	feature,
});

export const getGroup = query => ({
	type: types.GET_GROUP,
	query,
});

export const setGroup = feature => ({
	type: types.SET_GROUP,
	feature,
});

export const setSelect = feature => ({
	type: types.SET_FEATURE_PER_GROUP,
	feature
})

export const addFeaturePerGroup = feature => ({
	type: types.ADD_FEATURE_PER_GROUP,
	feature,
});

export const editFeaturePerGroup = (feature, id) => ({
	type: types.EDIT_FEATURE_PER_GROUP,
	feature,
	id,
});

export const deleteFeaturePerGroup = feature => ({
	type: types.DELETE_FEATURE_PER_GROUP,
	feature,
});

export default {
	getListFeaturePerGroup,
	setListFeaturePerGroup,
	addFeaturePerGroup,
	editFeaturePerGroup,
	deleteFeaturePerGroup,
	setSelect,
	getGroup,
	setGroup
};
