import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as despVendas from '../../../api/appApi/despVendas';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';
import * as cadTypes from '../../../api/appApi/cadTypes'

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;


	try {
		const response = yield call(despVendas.get, query);

		yield put(actions.set(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Despesas.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteTipo(payload) {
	
	const { desp } = payload;
	
	try {
		const response = yield call(despVendas.deleteDesp, desp);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Despesas deletado com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.get(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Despesas.',
				'error',
			),
		);
	}
}

export function* newDesp(payload) {
	
	
	const { tipo } = payload;
	
	if(typeof tipo.desc == 'undefined' || typeof tipo.percent == 'undefined'){
		yield put(
			notificationActions.addNotification(
				'Algum campo está vazio!.',
				'error',
			),
			
		);
	}else{
		try {
			const response = yield call(despVendas.newDesp, tipo);
			if (response.data) {
				yield put(actions.toogleModal());
				yield put(
					notificationActions.addNotification(
						'Sucesso ao realizar cadastro de Despesas.',
						'success',
					),
					
				);
				yield put(actions.get());
			}
		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao realizar cadastro de Despesas.',
					'error',
				),
			);
		}
	}
}

export function* editDesp(payload) {

	const { tipos } = payload;
	
	const items = yield select(selectors.getItems);

	const edit = items.find(x => x.id === tipos);
	
	if (typeof edit.perc ==  'undefined' || typeof edit.dt_ini ==  'undefined') {
		
		yield put(
			notificationActions.addNotification(
				'Preencha todos os campos!.',
				'error',
			),
		);
		yield put(actions.get());
	} else {
		try {
			const response = yield call(despVendas.update, edit);
			if (response.data) {
				const index = items.findIndex(v => v.id === tipos);
				yield put(actions.deleteItem(index));
				yield put(
					notificationActions.addNotification(
						'Sucesso ao editar Despesas.',
						'success',
					),
				);
			}
		} catch (error) {
			yield put(
				notificationActions.addNotification(
					'Erro ao editar Despesas!.',
					'error',
				),
			);
		}
	}
}

export function* setItems(payload) {
	
	const { desp } = payload;
	const items = yield select(selectors.getItems);
	const add = items.some(val => desp.id === val.id);

	if (!add) {
		yield put(actions.addItem(desp));
	} else {
		const { field } = payload;
		const index = items.findIndex(v => v.id === desp.id);
		yield put(actions.editItem(desp, index, field));
	}
}

export default function* wacthDesp() {
	yield takeLatest(types.GET_LIST_DESP, get);
	yield takeLatest(types.DELETE_DESP, deleteTipo);
	yield takeLatest(types.NEW_DESP, newDesp);
	yield takeLatest(types.EDIT_DESP, editDesp);
	yield takeLatest(types.SET_ITEMS_DESP, setItems);
}
