import api from './api';

export const newTipo= tipo =>
	api.post('/cadastro-tipos/setTipo', tipo);

	export const get = query =>
	api.get('/cadastro-tipos/get', { params: query });

	export const update = tipo =>
	api.put(`/cadastro-tipos/edit/${tipo.id}`, tipo); 

	export const deleteTipo = id =>
	api.delete(`/cadastro-tipos/delete/${id}`);