import React from 'react';
import Authorize from './Authorize';

const DashboardPage = React.lazy(() =>
	import('../screens/dashboard/DashboardPage'),
);
const ProfilePage = React.lazy(() => import('../screens/profile/ProfilePage'));
const ChangePasswordPage = React.lazy(() =>
	import('../screens/profile/ChangePasswordPage'),
);
const UsersPage = React.lazy(() => import('../screens/users/UsersPage'));
const UserRegisterPage = React.lazy(() =>
	import('../screens/users/UserRegisterPage'),
);
const UserPermissionsPage = React.lazy(() =>
	import('../screens/users/UserPermissionsPage'),
);
const GroupsPage = React.lazy(() => import('../screens/groups/GroupsPage'));
const GroupRegisterPage = React.lazy(() =>
	import('../screens/groups/GroupRegisterPage'),
);
const GroupPermissionsPage = React.lazy(() =>
	import('../screens/groups/GroupPermissionsPage'),
);
const HelpPage = React.lazy(() => import('../screens/help/HelpPage'));
const ProductionSchedulePage = React.lazy(() =>
	import('../screens/production-schedule/ProductionSchedulePage'),
);
const DiscardAllocationAction = React.lazy(() =>
	import('../screens/discard-allocation/DiscardAllocationAction'),
);
const DiscardAllocationPage = React.lazy(() =>
	import('../screens/discard-allocation/DiscardAllocationPage'),
);
const AdvertisingBudgetPage = React.lazy(() =>
	import('../screens/advertising-budget/AdvertisingBudgetPage'),
);
const CommercialDescriptionPage = React.lazy(() =>
	import('../screens/commercial-description/CommercialDescriptionPage'),
);

const CommercialDescriptionRegisterPage = React.lazy(() =>
	import(
		'../screens/commercial-description/CommercialDescriptionRegisterPage'
	),
);

const ItemGroupLinePage = React.lazy(() =>
	import('../screens/item-group-line/ItemGroupLinePage'),
);

const ItemGroupLineRegisterPage = React.lazy(() =>
	import('../screens/item-group-line/ItemGroupLineRegisterPage'),
);
const PriceListGroupPage = React.lazy(() =>
	import('../screens/price-list-group/PriceListGroupPage'),
);

const PriceListGroupRegisterPage = React.lazy(() =>
	import('../screens/price-list-group/PriceListGroupRegisterPage'),
);

const CostReportPage = React.lazy(() =>
	import('../screens/cost-report/CostReportPage'),
);

const CostUpdateTable = React.lazy(() =>
	import('../screens/cost-updateTable/CostUpdateTable'),
);

const DespesasVendas = React.lazy(() =>
	import('../screens/despesas-vendas/DespesasVendas'),
);

const CadTipos = React.lazy(() => import('../screens/Cad-tipos/CadTipos'));

const groupsRegisterPage = React.lazy(() =>
	import('../screens/groups/GroupRegisterPage'),
);

const groupsPermissionsPage = React.lazy(() =>
	import('../screens/groups/GroupPermissionsPage'),
);

const FeaturePage = React.lazy(() => import('../screens/Feature/FeaturePage'));

const FeatureRegisterPage = React.lazy(() =>
	import('../screens/Feature/FeatureRegisterPage'),
);

const FeatureResponsePage = React.lazy(() =>
	import('../screens/FeatureResponse/FeatureResponsePage'),
);

const FeatureResponseRegisterPage = React.lazy(() =>
	import('../screens/FeatureResponse/FeatureResponseRegisterPage'),
);

const ItemFeaturePage = React.lazy(() =>
	import('../screens/item-feature/ItemFeaturePage'),
);

const ItemFeatureRegisterPage = React.lazy(() =>
	import('../screens/item-feature/ItemFeatureRegisterPage'),
);

const CompositionFeaturePage = React.lazy(() =>
	import('../screens/compositionFeature/CompositionFeaturePage'),
);

const CompositionFeatureRegisterPage = React.lazy(() =>
	import('../screens/compositionFeature/CompositionFeatureRegisterPage'),
);

const itemBoxPage = React.lazy(() =>
	import('../screens/item-box-item-unity/itemBoxPage'),
);

const itemBoxRegisterPage = React.lazy(() =>
	import('../screens/item-box-item-unity/itemBoxRegisterPage'),
);

const ItemSituationPage = React.lazy(() =>
	import('../screens/item-situation/ItemSituationPage'),
);

const ItemSituationRegisterPage = React.lazy(() =>
	import('../screens/item-situation/ItemSituationRegisterPage'),
);

const DescriptionItemPage = React.lazy(() =>
	import('../screens/descriptionItem/descriptionItemPage'),
);

const DescriptionItemRegisterPage = React.lazy(() =>
	import('../screens/descriptionItem/descriptionItemRegisterPage'),
);

const DataSheetRegisterPage = React.lazy(() =>
	import('../screens/datasheet/DataSheetRegisterPage.js'),
);

const DataSheetPage = React.lazy(() =>
	import('../screens/datasheet/DataSheetPage.js'),
);

const DataSheetViewPage = React.lazy(() =>
	import('../screens/datasheet/DataSheetViewPage.js'),
);

const GroupItensPage = React.lazy(() =>
	import('../screens/group-itens/groupItensPage'),
);

const GroupItensRegisterPage = React.lazy(() =>
	import('../screens/group-itens/groupItensRegisterPage'),
);

const SpecificationParametersPage = React.lazy(() =>
	import('../screens/specification-parameters/specificationParametersPage'),
);

const SpecificationParametersRegisterPage = React.lazy(() =>
	import(
		'../screens/specification-parameters/specificationParametersRegisterPage'
	),
);

const ParametersResponsePage = React.lazy(() =>
	import('../screens/parameters-response/parametersResponsePage'),
);

const ParametersResponseRegisterPage = React.lazy(() =>
	import('../screens/parameters-response/parametersResponseRegisterPage'),
);

const ParamsForGroupPage = React.lazy(() =>
	import('../screens/params-for-group/ParamsForGroupPage'),
);

const ParamsForGroupRegisterPage = React.lazy(() =>
	import('../screens/params-for-group/ParamsForGroupRegisterPage'),
);

const ProductLinePage = React.lazy(() =>
	import('../screens/productLine/ProductLinePage'),
);

const ProductLineRegisterPage = React.lazy(() =>
	import('../screens/productLine/ProductLineRegisterPage'),
);

const FeaturePerGroupPage = React.lazy(() =>
	import('../screens/FeaturePerGroup/FeaturePerGroupPage'),
);

const FeaturePerGroupRegisterPage = React.lazy(() =>
	import('../screens/FeaturePerGroup/FeaturePerGroupRegisterPage'),
);

const sisdevinPage = React.lazy(() =>
	import('../screens/compositionSisdevin/CompositionSisdevinPage'),
);

const sisdevinRegisterPage = React.lazy(() =>
	import('../screens/compositionSisdevin/CompositionSisdevinRegisterPage'),
);

const transferMotivesPage = React.lazy(() =>
	import('../screens/transferMotives/TransferMotivesPage'),
);

const transferMotivesRegisterPage = React.lazy(() =>
	import('../screens/transferMotives/TransferMotivesRegisterPage'),
);

const warehousePage = React.lazy(() =>
	import('../screens/warehouse-sit/warehouseSitPage'),
);

const technicalDataPage = React.lazy(() =>
	import('../screens/TechnicalData/TechnicalDataPage'),
);

const additionMotivesPage = React.lazy(() =>
	import('../screens/AdditionMotives/AdditionMotivesPage'),
);
const additionMotivesRegisterPage = React.lazy(() =>
import('../screens/AdditionMotives/AdditionMotivesRegisterPage'),
);

const freDivergenceRegisterPage = React.lazy(() =>
import('../screens/freDivergence/FreDivergence'),
);

const User = Authorize(false, ['USER']);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: User(DashboardPage),
		permission: false,
	},
	{
		path: '/ajuda',
		name: 'Help',
		component: User(HelpPage),
		permission: false,
	},
	{
		path: '/perfil',
		name: 'Profile',
		component: User(ProfilePage),
		permission: false,
	},
	{
		path: '/perfil/alterar-senha',
		name: 'ChangePassword',
		component: User(ChangePasswordPage),
		permission: false,
	},
	{
		path: '/usuarios',
		name: 'Users',
		component: User(UsersPage),
		permission: true,
		id: 291,
	},
	{
		path: '/usuarios/adicionar',
		name: 'UserRegister',
		component: User(UserRegisterPage),
		permission: true,
		id: 291,
	},
	{
		path: '/usuarios/editar/:id',
		name: 'UserEdit',
		component: User(UserRegisterPage),
		permission: true,
		id: 291,
	},
	{
		path: '/usuarios/permissoes',
		name: 'UserPermissions',
		component: User(UserPermissionsPage),
		permission: true,
		id: 291,
	},
	{
		path: '/grupos',
		name: 'Groups',
		component: User(GroupsPage),
		permission: true,
		id: 292,
	},
	{
		path: '/grupos/adicionar',
		name: 'GroupRegister',
		component: User(groupsRegisterPage),
		permission: true,
		id: 292,
	},
	{
		path: '/grupos/editar/:id',
		name: 'UserEdit',
		component: User(groupsRegisterPage),
		permission: true,
		id: 292,
	},
	{
		path: '/grupos/permissoes/:id',
		name: 'UserPermissions',
		component: User(groupsPermissionsPage),
		permission: true,
		id: 292,
	},
	{
		path: '/programacao-producao',
		name: 'ProductionSchedule',
		component: User(ProductionSchedulePage),
		permission: true,
		id: 264,
	},
	{
		path: '/rateio-descartes',
		name: 'DiscardAllocation',
		component: User(DiscardAllocationPage),
		permission: true,
		id: 303,
	},
	{
		path: '/rateio-descartes/ratear',
		name: 'DiscardAllocationAction',
		component: User(DiscardAllocationAction),
		permission: true,
		id: 303,
	},
	{
		path: '/verbas-publicitarias',
		name: 'AdvertisingBudget',
		component: User(AdvertisingBudgetPage),
		permission: true,
		id: 249,
	},
	{
		path: '/descricao-comercial',
		name: 'CommercialDescription',
		component: User(CommercialDescriptionPage),
		permission: true,
		id: 237,
	},
	{
		path: '/descricao-comercial/adicionar',
		name: 'CommercialDescription add',
		component: User(CommercialDescriptionRegisterPage),
		permission: true,
		id: 237,
	},
	{
		path: '/descricao-comercial/editar/:id',
		name: 'CommercialDescription edit',
		component: User(CommercialDescriptionRegisterPage),
		permission: true,
		id: 237,
	},
	{
		path: '/grupos-linhas-produto',
		name: 'ItemGroupLine',
		component: User(ItemGroupLinePage),
		permission: true,
		id: 238,
	},
	{
		path: '/grupos-linhas-produto/adicionar',
		name: 'ItemGroupLineRegister',
		component: User(ItemGroupLineRegisterPage),
		permission: true,
		id: 238,
	},
	{
		path: '/grupos-linhas-produto/editar/:id',
		name: 'ItemGroupLineEdit',
		component: User(ItemGroupLineRegisterPage),
		permission: true,
		id: 238,
	},
	{
		path: '/grupos-tabelas-precos',
		name: 'PriceListGroup',
		component: User(PriceListGroupPage),
		permission: true,
		id: 239,
	},
	{
		path: '/grupos-tabelas-precos/adicionar',
		name: 'PriceListGroupRegisterPage',
		component: User(PriceListGroupRegisterPage),
		permission: true,
		id: 239,
	},
	{
		path: '/grupos-tabelas-precos/editar/:id',
		name: 'PriceListGroupRegisterEdit',
		component: User(PriceListGroupRegisterPage),
		permission: true,
		id: 239,
	},
	{
		path: '/tabela-vendas',
		name: 'CostReportPage',
		component: User(CostReportPage),
		permission: true,
		id: 244,
	},
	{
		path: '/atualizacao-tabela-vendas',
		name: 'CostReportPage',
		component: User(CostUpdateTable),
		permission: true,
		id: 305,
	},
	{
		path: '/despesas-vendas',
		name: 'DespesasVendas',
		component: User(DespesasVendas),
		permission: true,
		id: 250,
	},

	// Comercial
	{
		path: '/comercia/cons/tech-data',
		name: 'DadosTecnicos',
		component: User(technicalDataPage),
		permission: true,
		id: 245,
	},
	// Feature
	{
		path: '/cadastro-tipos',
		name: 'CadastroTipos',
		component: User(CadTipos),
		permission: true,
		id: 251,
	},
	{
		path: '/sup/cad/feature',
		name: 'itemFeature',
		component: User(FeaturePage),
		permission: true,
		id: 246,
	},
	{
		path: '/sup/cad/feature/adicionar',
		name: 'itemFeatureAdd',
		component: User(FeatureRegisterPage),
		permission: true,
		id: 246,
	},
	{
		path: '/sup/cad/feature/editar/:id',
		name: 'itemFeatureEdit',
		component: User(FeatureRegisterPage),
		permission: true,
		id: 246,
	},
	// Feature Response
	{
		path: '/sup/cad/feature-response',
		name: 'FeatureResponse',
		component: User(FeatureResponsePage),
		permission: true,
		id: 236,
	},
	{
		path: '/sup/cad/feature-response/adicionar',
		name: 'FeatureResponseAdd',
		component: User(FeatureResponseRegisterPage),
		permission: true,
		id: 236,
	},
	{
		path: '/sup/cad/feature-response/editar/:id',
		name: 'FeatureResponseEdit',
		component: User(FeatureResponseRegisterPage),
		permission: true,
		id: 236,
	},
	// Item feature
	// {
	// 	path: '/sup/cad/item-feature',
	// 	name: 'Feature',
	// 	component: User(ItemFeaturePage),
	// 	permission: true,
	// 	id: 246,
	// },
	// {
	// 	path: '/sup/cad/item-feature/adicionar',
	// 	name: 'FeatureAdd',
	// 	component: User(ItemFeatureRegisterPage),
	// 	permission: true,
	// 	id: 246,
	// },
	// {
	// 	path: '/sup/cad/item-feature/editar/:id',
	// 	name: 'FeatureEdit',
	// 	component: User(ItemFeatureRegisterPage),
	// 	permission: true,
	// 	id: 246,
	// },
	//Composition Feature
	{
		path: '/laboratory/cad/composition-feature',
		name: 'Feature',
		component: User(CompositionFeaturePage),
		permission: true,
		id: 263,
	},
	{
		path: '/laboratory/cad/composition-feature/adicionar',
		name: 'FeatureAdd',
		component: User(CompositionFeatureRegisterPage),
		permission: true,
		id: 263,
	},
	{
		path: '/laboratory/cad/composition-feature/editar/:id',
		name: 'FeatureEdit',
		component: User(CompositionFeatureRegisterPage),
		permission: true,
		id: 263,
	},
	// Item Caixa x Item Unidade
	{
		path: '/sup/cad/item',
		name: 'Item caixa x Item lista',
		component: User(itemBoxPage),
		permission: true,
		id: 275,
	},
	{
		path: '/sup/cad/item/adicionar',
		name: 'Item caixa x Item add',
		component: User(itemBoxRegisterPage),
		permission: true,
		id: 275,
	},
	{
		path: '/sup/cad/item/editar/:id',
		name: 'Item caixa x Item edit',
		component: User(itemBoxRegisterPage),
		permission: true,
		id: 275,
	},
	//DESCRIÇÃO DE ITEM INTERNA
	{
		path: '/descricao-item',
		name: 'Descrição Item Interna',
		component: User(DescriptionItemPage),
		permission: true,
		id: 315,
	},
	{
		path: '/descricao-item/adicionar',
		name: 'Descrição Item InternaAdd',
		component: User(DescriptionItemRegisterPage),
		permission: true,
		id: 315,
	},
	{
		path: '/descricao-item/editar/:id',
		name: 'Descrição Item InternaEdit',
		component: User(DescriptionItemRegisterPage),
		permission: true,
		id: 315,
	},
	//SITUAÇÃO DE ITEM
	{
		path: '/stock/cons/item-sit',
		name: 'Situação de Item',
		component: User(ItemSituationPage),
		permission: true,
		id: 260,
	},
	{
		path: '/stock/cons/item-sit/adicionar',
		name: 'Situação de ItemAdd',
		component: User(ItemSituationRegisterPage),
		permission: true,
		id: 260,
	},
	{
		path: '/stock/cons/item-sit/editar/:id',
		name: 'Situação de ItemEdit',
		component: User(ItemSituationRegisterPage),
		permission: true,
		id: 260,
	},
	//Ficha Técnica
	{
		path: '/datasheet',
		name: 'Datasheet list',
		component: User(DataSheetPage),
		permission: true,
		id: 274,
	},
	//Ficha Técnica
	{
		path: '/datasheet/view/:id',
		name: 'Datasheet View',
		component: User(DataSheetViewPage),
		permission: true,
		id: 274,
	},
	{
		path: '/datasheet/cad/datasheet',
		name: 'Datasheet add',
		component: User(DataSheetRegisterPage),
		permission: true,
		id: 274,
	},
	{
		path: '/datasheet/cad/datasheet/editar/:id',
		name: 'Datasheet edit',
		component: User(DataSheetRegisterPage),
		permission: true,
		id: 274,
	},
	//GRUPOS DE ITENS
	{
		path: '/sup/cad/group-itens',
		name: 'Grupos de Itens',
		component: User(GroupItensPage),
		permission: true,
		id: 316,
	},
	{
		path: '/sup/cad/group-itens/adicionar',
		name: 'Grupos de ItensAdd',
		component: User(GroupItensRegisterPage),
		permission: true,
		id: 316,
	},
	{
		path: '/sup/cad/group-itens/editar/:id',
		name: 'Grupos de ItensEdit',
		component: User(GroupItensRegisterPage),
		permission: true,
		id: 316,
	},
	//PARAMETRO DE ESPECIFICAÇÃO
	{
		path: '/qualid/cad/specification',
		name: 'Justificativa de Divergência de Frete',
		component: User(SpecificationParametersPage),
		permission: true,
		id: 318,
	},
	{
		path: '/qualid/cad/specification/adicionar',
		name: 'Justificativa de Divergência de FreteAdd',
		component: User(SpecificationParametersRegisterPage),
		permission: true,
		id: 318,
	},
	{
		path: '/qualid/cad/specification/editar/:id',
		name: 'Parâmetros de EspecificaçõesEdit',
		component: User(SpecificationParametersRegisterPage),
		permission: true,
		id: 318,
	},
	//RESPOSTAS DE PARAMETROS
	{
		path: '/qualid/cad/parameters-response',
		name: 'Respostas de Parâmetros',
		component: User(ParametersResponsePage),
		permission: true,
		id: 319,
	},
	{
		path: '/qualid/cad/parameters-response/adicionar',
		name: 'Respostas de ParâmetrosAdd',
		component: User(ParametersResponseRegisterPage),
		permission: true,
		id: 319,
	},
	{
		path: '/qualid/cad/parameters-response/editar/:id',
		name: 'Respostas de ParâmetrosEdit',
		component: User(ParametersResponseRegisterPage),
		permission: true,
		id: 319,
	},
	// PARAMS FOR GROUP
	{
		path: '/qualid/cad/params-for-group',
		name: 'Parâmetros por grupo',
		component: User(ParamsForGroupPage),
		permission: true,
		id: 320,
	},
	{
		path: '/qualid/cad/params-for-group/adicionar',
		name: 'Parâmetros por grupoAdd',
		component: User(ParamsForGroupRegisterPage),
		permission: true,
		id: 320,
	},
	{
		path: '/qualid/cad/params-for-group/editar/:id',
		name: 'Parâmetros por grupoEdit',
		component: User(ParamsForGroupRegisterPage),
		permission: true,
		id: 320,
	},
	//Product Line
	{
		path: '/commercial/cad/product-line',
		name: 'Parâmetros por grupo',
		component: User(ProductLinePage),
		permission: true,
		id: 322,
	},
	{
		path: '/commercial/cad/product-line/adicionar',
		name: 'Parâmetros por grupoAdd',
		component: User(ProductLineRegisterPage),
		permission: true,
		id: 322,
	},
	{
		path: '/commercial/cad/product-line/editar/:id',
		name: 'Parâmetros por grupoEdit',
		component: User(ProductLineRegisterPage),
		permission: true,
		id: 322,
	},
	//Feature per group
	{
		path: '/supplies/cad/feature-per-group',
		name: 'Parâmetros por grupo',
		component: User(FeaturePerGroupPage),
		permission: true,
		id: 323,
	},
	{
		path: '/supplies/cad/feature-per-group/adicionar',
		name: 'Parâmetros por grupoAdd',
		component: User(FeaturePerGroupRegisterPage),
		permission: true,
		id: 323,
	},
	{
		path: '/supplies/cad/feature-per-group/editar/:id',
		name: 'Parâmetros por grupoEdit',
		component: User(FeaturePerGroupRegisterPage),
		permission: true,
		id: 323,
	},
	//Sisdevin
	{
		path: '/laboratory/cad/sisdevin',
		name: 'Classificação sisdevin',
		component: User(sisdevinPage),
		permission: true,
		id: 324,
	},
	{
		path: '/laboratory/cad/sisdevin/adicionar',
		name: 'Classificação sisdevinAdd',
		component: User(sisdevinRegisterPage),
		permission: true,
		id: 324,
	},
	{
		path: '/laboratory/cad/sisdevin/editar/:id',
		name: 'Classificação sisdevinEdit',
		component: User(sisdevinRegisterPage),
		permission: true,
		id: 324,
	},

	//Transfer Motives
	{
		path: '/stock/transfer-motives',
		name: 'Motivos Tranferência',
		component: User(transferMotivesPage),
		permission: true,
		id: 329,
	},
	{
		path: '/stock/transfer-motives/adicionar',
		name: 'Motivos TranferênciaAdd',
		component: User(transferMotivesRegisterPage),
		permission: true,
		id: 329,
	},
	{
		path: '/stock/transfer-motives/editar/:id',
		name: 'Motivos TranferênciaEdit',
		component: User(transferMotivesRegisterPage),
		permission: true,
		id: 329,
	},

	// Situação almoxerifado
	{
		path: '/stock/cons/almox-sit',
		name: 'Situação de Almoxarifado',
		component: User(warehousePage),
		permission: true,
		id: 261,
	},
	//Addition Motives
	{
		path: '/cost/addition-motives',
		name: 'Justificativa de Divergência de Frete',
		component: User(additionMotivesPage),
		permission: true,
		id: 333,
	},
	{
		path: '/cost/addition-motives/adicionar',
		name: 'Justificativa de Divergência de FreteAdd',
		component: User(additionMotivesRegisterPage),
		permission: true,
		id: 333,
	},
	{
		path: '/cost/addition-motives/editar/:id',
		name: 'Justificativa de Divergência de FreteEdit',
		component: User(additionMotivesRegisterPage),
		permission: true,
		id: 333,
	},
	//Divergência de Frete
	{
		path: '/log/fre-divergence',
		name: 'Divergência de Fretes',
		component: User(freDivergenceRegisterPage),
		permission: true,
		id: 335,
	},
];

export default routes;
