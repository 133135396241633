import api from './api';

export const get = body => api.post('/supplies/production-schedule', body);

export const getPurchaseOrders = (emprId, itemId) =>
	api.get(
		`/supplies/production-schedule/purchase-orders?emprId=${emprId}&itemId=${itemId}`,
	);

export const getProg = (start, end, itemId) =>
	api.get(
		`/supplies/productionSchedule/listProd?start=${start}&end=${end}&itemId=${itemId}`,
	);