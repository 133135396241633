import {
	SET_CLASSIFICATIONS,
	SET_ITENS,
	SET_SUPPLIERS,
	SET_ITENS_STOCK,
	SET_LINE,
	SET_UFS,
	SET_SEGMENTS,
	SET_ITEM,
	SET_SALES_TABLE,
	SET_ITENS_UNI,
	SET_ENTERPRISES
} from './types';

const initialState = {
	classifications: [],
	suppliers: [],
	itens: [],
	itensStock: [],
	lines: [],
	ufs: [],
	segments: [],
	salesTable: [],
	itensQuality: [],
	itensUni: [],
	enterprises: []
};

const genericsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CLASSIFICATIONS:
			return {
				...state,
				classifications: action.classifications,
			};
		case SET_ITENS:
			return {
				...state,
				itens: action.itens,
			};
		case SET_ITENS_STOCK:
			return {
				...state,
				itensStock: action.itens,
			};
		case SET_SUPPLIERS:
			return {
				...state,
				suppliers: action.suppliers,
			};
		case SET_LINE:
			return {
				...state,
				lines: action.lines,
			};
		case SET_UFS:
			return {
				...state,
				ufs: action.ufs,
			};
		case SET_SEGMENTS:
			return {
				...state,
				segments: action.segments,
			};
		case SET_SALES_TABLE:
			return {
				...state,
				salesTable: action.sales,
			};
		case SET_ITEM:
			return {
				...state,
				itensQuality: action.itens,
			};
		case SET_ITENS_UNI:
			return {
				...state,
				itensUni: action.itens,
			};
		case SET_ENTERPRISES:
			return {
				...state,
				enterprises: action.enterprises,
			};
		default:
			return state;
	}
};

export default genericsReducer;
