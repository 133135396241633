import * as types from './types';

export const get = query => ({
	type: types.GET_LIST_COMMERCIAL,
	query,
});

export const set = list => ({
	type: types.SET_LIST_COMMERCIAL,
	list,
});

export const deleteCommercial = commercial => ({
	type: types.DELETE_COMMERCIAL,
	commercial,
});

export const newCommercial = commercial => ({
	type: types.NEW_COMMERCIAL,
	commercial,
});

export const select = commercial => ({
	type: types.SELECT_COMMERCIAL,
	commercial,
});

export const toogleModal = () => ({
	type: types.MODAL_COMMERCIAL,
});

export const setItems = item => ({
	type: types.SET_ITEMS_COMMERCIAL,
	item,
});

export const editItem = (item, index) => ({
	type: types.EDIT_ITEM_COMMERCIAL,
	item,
	index,
});

export const addItem = item => ({
	type: types.ADD_ITEM_COMMERCIAL,
	item,
});

export const editCommercial = (commercial, id) => ({
	type: types.EDIT_COMMERCIAL,
	commercial,
	id
});

export const deleteItem = index => ({
	type: types.DELETE_ITEM_COMMERCIAL,
	index,
});

export const resetItems = () => ({
	type: types.RESET_ITEMS_COMMERCIAL,
});

export default {
	get,
	set,
	deleteCommercial,
	newCommercial,
	select,
	toogleModal,
	setItems,
	editItem,
	addItem,
	editCommercial,
	deleteItem,
	resetItems,
};
