import { SET_TRANSFER_MOTIVES, SELECT_TRANSFER_MOTIVES } from './types';

const initialState = {
	list: [],
	select: false,
};

const transferMotivesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TRANSFER_MOTIVES:
			return {
				...state,
				list: action.transfer,
			};
		case SELECT_TRANSFER_MOTIVES:
			return {
				...state,
				select: action.transfer,
			};
		default:
			return state;
	}
};

export default transferMotivesReducer;
