import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as warehouse from '../../../api/appApi/warehouseSit';
import { navigate } from '../../../lib/utils/navigation';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* getList(payload) {
	yield put(apiActions.apiStart());
	yield put(apiActions.setQueryFilter(payload.query));

	try {
		const response = yield call(warehouse.getAll, payload.query);
		yield put(actions.setList(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Situações.',
				'error',
			),
		);
	}
	yield put(apiActions.apiEnd());
}

export function* getListAlmox(payload) {
	yield put(apiActions.apiStart());
	yield put(apiActions.setQueryFilter(payload.query));

	try {
		const response = yield call(warehouse.getAlmox, payload.query);
		yield put(actions.setListAlmox(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar almoxerifados.',
				'error',
			),
		);
	}
	yield put(apiActions.apiEnd());
}


export default function* watchWarehouse() {
	yield takeLatest(types.GET_LIST, getList);
	yield takeLatest(types.GET_LIST_ALMOX, getListAlmox);
}
