import { SET_PRICE_LIST, SET_PRICE } from './types';

const initialState = {
	list: [],
	price: false,
};

const priceListReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PRICE_LIST:
			return {
				...state,
				list: action.price,
			};
		case SET_PRICE:
			return {
				...state,
				price: action.price,
			};
		default:
			return state;
	}
};

export default priceListReducer;
