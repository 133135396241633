import api from './api';

export const get = query =>
	api.get('/stock/transfer-motives', { params: query });

export const deleteTransfer = id => api.delete(`/stock/transfer-motives/${id}`);

export const newTransfer = transfer => api.post('/stock/transfer-motives', transfer);

export const editTransfer = (transfer, id) =>
	api.put(`/stock/transfer-motives/${id}`, transfer);
