export const GET_FEATURE_PER_GROUP_LIST = 'feature-per-group/GET_FEATURE_PER_GROUP_LIST';
export const SET_FEATURE_PER_GROUP_LIST = 'feature-per-group/SET_FEATURE_PER_GROUP_LIST';
export const SET_FEATURE_PER_GROUP = 'feature-per-group/SET_FEATURE_PER_GROUP';
export const GET_FEATURE_PER_GROUP = 'feature-per-group/GET_FEATURE_PER_GROUP';
export const ADD_FEATURE_PER_GROUP = 'feature-per-group/ADD_FEATURE_PER_GROUP';
export const EDIT_FEATURE_PER_GROUP = 'feature-per-group/EDIT_FEATURE_PER_GROUP';
export const DELETE_FEATURE_PER_GROUP = 'feature-per-group/DELETE_FEATURE_PER_GROUP';
export const GET_GROUP = 'feature-per-group/GET_GROUP';
export const SET_GROUP = 'feature-per-group/SET_GROUP';

export default {
	GET_FEATURE_PER_GROUP_LIST,
	SET_FEATURE_PER_GROUP_LIST,
	SET_FEATURE_PER_GROUP,
	GET_FEATURE_PER_GROUP,
	ADD_FEATURE_PER_GROUP,
	EDIT_FEATURE_PER_GROUP,
	DELETE_FEATURE_PER_GROUP,
	GET_GROUP,
	SET_GROUP
};
