import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as datasheetApi from '../../../api/appApi/datasheet';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';

export function* getStructMaterial(payload) {
	yield put(apiActions.apiSubmitStart());

	const { material } = payload;

	yield put(apiActions.setQueryFilter(material));
	try {
		const response = yield call(datasheetApi.getStruct, material);
		yield put(actions.addMaterial(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Estrutura.',
				'error',
			),
		);
	}
	yield put(apiActions.apiSubmitEnd());
}

export function* getDatasheet(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(datasheetApi.getDatasheet, query);
		yield put(actions.setDatasheet(response.data.data));
		const responseMaterial = query ? yield call(datasheetApi.getMaterial, query) : [];
		yield put(actions.setMaterial(responseMaterial.data ? responseMaterial.data.data : responseMaterial));
	} catch (error) {
		console.log(error)
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Caracteristicas.',
				'error',
			),
		);
	}
	yield put(apiActions.apiSubmitEnd());
}

export function* addDatasheet(payload) {
	const { datasheet } = payload;
	let material = yield select(selectors.getMaterial);

	try {
		const response = yield call(datasheetApi.addDatasheet, datasheet);
		if (material) {
			material = material.map(index => ({
				FT_ID: response.data.data.id,
				ITEMPR_ID: index.ITEMPR_ID,
				GARRAFA: index.GARRAFA ? 1 : 0,
				CAIXA: index.CAIXA ? 1 : 0,
				COMPOSICAO: index.COMPOSICAO ? 1 : 0,
				IMAGEM: index.IMAGEM || 'NULL',
				ORDEM: index.ORDEM
			}))
			const responseMaterial = yield call(datasheetApi.addMaterial, material, response.data.data.id);
		}
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Ficha Técnica cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/datasheet');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao Ficha Técnica.',
				'error',
			),
		);
	}
}

export function* editDatasheet(payload) {
	const { datasheet } = payload;
	const { id } = payload;
	let material = yield select(selectors.getMaterial);

	try {
		const response = yield call(datasheetApi.editDatasheet, datasheet, id);
		if (material) {
			material = material.map(index => ({
				FT_ID: response.data.data.id,
				ITEMPR_ID: index.ITEMPR_ID,
				GARRAFA: index.GARRAFA ? 1 : 0,
				CAIXA: index.CAIXA ? 1 : 0,
				COMPOSICAO: index.COMPOSICAO ? 1 : 0,
				IMAGEM: index.IMAGEM || 'NULL',
				ORDEM: index.ORDEM
			}))
			const responseMaterial = yield call(datasheetApi.addMaterial, material, response.data.data.id);
		}
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Ficha Técnica cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/datasheet');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao Ficha Técnica.',
				'error',
			),
		);
	}
}
export function* deleteDatasheet(payload) {
	const { id } = payload;

	try {
		const response = yield call(datasheetApi.deleteDatasheet, id);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Ficha técnica deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getDatasheet(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Ficha técnica.',
				'error',
			),
		);
	}
}

export default function* watchDataSheet() {
	yield takeLatest(types.GET_DATASHEET, getDatasheet);
	yield takeLatest(types.ADD_DATASHEET, addDatasheet);
	yield takeLatest(types.GET_STRUCT_MATERIAL, getStructMaterial);
	yield takeLatest(types.EDIT_DATASHEET, editDatasheet);
	yield takeLatest(types.DELETE_DATASHEET, deleteDatasheet);
}
