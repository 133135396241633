import * as types from './types';

export const getDescriptionItem = query => ({
	type: types.GET_DESCRIPTION_ITEM,
	query,
});

export const setDescriptionItem = item => ({
	type: types.SET_DESCRIPTION_ITEM,
	item,
});

export const addDescriptionItem = item => ({
	type: types.ADD_DESCRIPTION_ITEM,
	item,
});

export const editDescriptionItem = (item, id) => ({
	type: types.EDIT_DESCRIPTION_ITEM,
	item,
	id,
});

export const deleteDescriptionItem = item => ({
	type: types.DELETE_DESCRIPTION_ITEM,
	item,
});

export const setSelectDescriptionItem = item => ({
	type: types.SET_SELECT_DESCRIPTION_ITEM,
	item,
});

export const getAllItem = query => ({
	type: types.GET_ALL_ITEMS,
	query,
});

export const setAllItem = itens => ({
	type: types.SET_ALL_ITEMS,
	itens,
});

export default {
	getDescriptionItem,
	setDescriptionItem,
	addDescriptionItem,
	editDescriptionItem,
	deleteDescriptionItem,
	setSelectDescriptionItem,
	getAllItem,
	setAllItem
};
