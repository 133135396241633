import {
	SELECT_DISCARD,
	SET_DISCARD_LIST,
	SET_HISTORIC_DISCARD,
	ADD_ITEM_DISCARD,
	EDIT_ITEM_DISCARD,
	RESET_ITEMS_DISCARD,
	INITIAL_ITEMS_DISCARD,
} from './types';

const initialState = {
	list: [],
	historic: [],
	discard: false,
	items: [],
};

const productionScheduleReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DISCARD_LIST:
			return {
				...state,
				list: action.list,
			};
		case SET_HISTORIC_DISCARD:
			return {
				...state,
				historic: action.historic,
			};
		case SELECT_DISCARD:
			return {
				...state,
				discard: action.discard,
			};
		case ADD_ITEM_DISCARD:
			return {
				...state,
				items: state.items.concat(action.item),
			};
		case EDIT_ITEM_DISCARD:
			return {
				...state,
				items: state.items.map((i, key) =>
					key === action.index ? action.item : i,
				),
			};
		case RESET_ITEMS_DISCARD:
			return {
				...state,
				items: [],
			};
		case INITIAL_ITEMS_DISCARD:
			return {
				...state,
				items: action.items,
			};
		default:
			return state;
	}
};

export default productionScheduleReducer;
