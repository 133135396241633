import * as types from './types';

export const getProductLine = query => ({
	type: types.GET_PRODUCT_LINE,
	query,
});

export const setProductLine = product => ({
	type: types.SET_PRODUCT_LINE,
	product,
});

export const addProductLine = product => ({
	type: types.ADD_PRODUCT_LINE,
	product,
});

export const editProductLine = (product, id) => ({
	type: types.EDIT_PRODUCT_LINE,
	product,
	id,
});

export const deleteProductLine = product => ({
	type: types.DELETE_PRODUCT_LINE,
	product,
});

export const setSelectProductLine = product => ({
	type: types.SET_SELECT_PRODUCT_LINE,
	product,
});

export default {
	getProductLine,
	setProductLine,
	addProductLine,
	editProductLine,
	deleteProductLine,
	setSelectProductLine,
};
