import { call, put, takeLatest } from 'redux-saga/effects';
import * as generics from '../../../api/appApi/generics';
import { notificationActions } from '../notification';
import * as actions from './actions';
import * as types from './types';
import { apiActions } from '../api';

export function* getClassifications(payload) {
	console.log(payload)
	try {
		const response = yield call(generics.getClassifications, payload.data);

		yield put(actions.setClassifications(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar classificação de itens.',
				'error',
			),
		);
	}
}

export function* getLine() {
	try {
		const response = yield call(generics.getLine);

		yield put(actions.setLine(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar linhas de produto.',
				'error',
			),
		);
	}
}

export function* getUfs() {
	try {
		const response = yield call(generics.getUfs);

		yield put(actions.setUfs(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification('Erro ao buscar ufs.', 'error'),
		);
	}
}

export function* getSegments() {
	try {
		const response = yield call(generics.getSegments);

		yield put(actions.setSegments(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar segmentos.',
				'error',
			),
		);
	}
}

export function* getSalesTable() {
	try {
		const response = yield call(generics.getSalesTable);

		yield put(actions.setSalesTable(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar tabelas de venda.',
				'error',
			),
		);
	}
}

export function* getItensByClassification(payload) {
	const { query } = payload;

	try {
		const response = yield call(generics.getItensByClassification, query);

		yield put(actions.setItens(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar itens.',
				'error',
			),
		);
	}
}

export function* getItensByStock(payload) {
	const { query } = payload;

	try {
		const response = yield call(generics.getItensByStock, query);

		yield put(actions.setItensByStock(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar itens.',
				'error',
			),
		);
	}
}

export function* getItens(payload) {
	const { query } = payload;

	try {
		const response = yield call(generics.getItens, query);

		yield put(actions.setItem(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar itens.',
				'error',
			),
		);
	}
}

export function* getItensUni(payload) {
	const { query } = payload;

	try {
		const response = yield call(generics.getItensUni, query);

		yield put(actions.setItemUni(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar itens.',
				'error',
			),
		);
	}
}

export function* getSuppliers(payload) {
	yield put(apiActions.apiStart());

	const { query } = payload;

	try {
		const response = yield call(generics.getSuppliers, query);

		yield put(actions.setSuppliers(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar fornecedores.',
				'error',
			),
		);
	}

	yield put(apiActions.apiEnd());
}

export function* getEnterprises() {
	try {
		const response = yield call(generics.getEnterprises);

		yield put(actions.setEnterprises(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar empresas.',
				'error',
			),
		);
	}
}

export default function* watchGenerics() {
	yield takeLatest(types.GET_CLASSIFICATIONS, getClassifications);
	yield takeLatest(types.GET_ITENS_CLASSIFICATION, getItensByClassification);
	yield takeLatest(types.GET_SUPPLIERS, getSuppliers);
	yield takeLatest(types.GET_ITENS_STOCK, getItensByStock);
	yield takeLatest(types.GET_LINE, getLine);
	yield takeLatest(types.GET_UFS, getUfs);
	yield takeLatest(types.GET_SEGMENTS, getSegments);
	yield takeLatest(types.GET_SALES_TABLE, getSalesTable);
	yield takeLatest(types.GET_ITENS, getItens);
	yield takeLatest(types.GET_ITENS_UNI, getItensUni);
	yield takeLatest(types.GET_ENTERPRISES, getEnterprises);
}
