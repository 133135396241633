export const GET_PRODUCT_LINE = 'product-line/GET_PRODUCT_LINE';
export const SET_PRODUCT_LINE = 'product-line/SET_PRODUCT_LINE';
export const ADD_PRODUCT_LINE = 'product-line/ADD_PRODUCT_LINE';
export const EDIT_PRODUCT_LINE = 'product-line/EDIT_PRODUCT_LINE';
export const DELETE_PRODUCT_LINE = 'product-line/DELETE_PRODUCT_LINE';
export const SET_SELECT_PRODUCT_LINE =
	'product-line/SET_SELECT_PRODUCT_LINE';

export default {
	GET_PRODUCT_LINE,
	SET_PRODUCT_LINE,
	ADD_PRODUCT_LINE,
	EDIT_PRODUCT_LINE,
	DELETE_PRODUCT_LINE,
	SET_SELECT_PRODUCT_LINE,
};
