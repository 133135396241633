export const GET_FEATURE = 'feature/GET_TYPES_FEATURE';
export const SET_FEATURE = 'feature/SET_FEATURE';
export const ADD_FEATURE = 'feature/ADD_FEATURE';
export const EDIT_FEATURE = 'feature/EDIT_FEATURE';
export const DELETE_FEATURE = 'feature/DELETE_FEATURE';

export default {
	GET_FEATURE,
	SET_FEATURE,
	ADD_FEATURE,
	EDIT_FEATURE,
	DELETE_FEATURE
};
