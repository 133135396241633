import * as types from './types';

export const getListReport = query => ({
	type: types.GET_REPORT_LIST,
	query,
});

export const setListReport = list => ({
	type: types.SET_REPORT_LIST,
	list,
});

export const setColumns = columns => ({
	type: types.SET_REPORT_COLUMNS,
	columns,
});

export default {
	getListReport,
	setListReport,
	setColumns,
};
