export const GET_ADDITION_MOTIVES = 'addition-motives/GET_ADDITION_MOTIVES';
export const SET_ADDITION_MOTIVES = 'addition-motives/SET_ADDITION_MOTIVES';
export const ADD_ADDITION_MOTIVES = 'addition-motives/ADD_ADDITION_MOTIVES';
export const EDIT_ADDITION_MOTIVES = 'addition-motives/EDIT_ADDITION_MOTIVES';
export const DELETE_ADDITION_MOTIVES = 'addition-motives/DELETE_ADDITION_MOTIVES';
export const SELECT_ADDITION_MOTIVES = 'addition-motives/SELECT_ADDITION_MOTIVES';

export default {
	GET_ADDITION_MOTIVES,
	SET_ADDITION_MOTIVES,
	ADD_ADDITION_MOTIVES,
	EDIT_ADDITION_MOTIVES,
	DELETE_ADDITION_MOTIVES,
	SELECT_ADDITION_MOTIVES
};
