import * as types from './types';

export const get = query => ({
	type: types.GET_LIST_TIPOS,
	query,
});

export const set = tipos => ({
	type: types.SET_LIST_TIPOS,
	tipos,
});

export const deleteTipo = tipo => ({
	type: types.DELETE_TIPO,
	tipo,
});

export const newTipo = tipo => ({
	type: types.NEW_TYPE,
	tipo,
});

export const select = select => ({
	type: types.SELECT_TIPOS,
	select,
});

export const toogleModal = () => ({
	type: types.MODAL_BUDGET,
});

export const setItems = (item, field) => ({
	type: types.SET_ITEMS_TIPOS,
	item,
	field,
});

export const editItem = (item, index, field) => ({
	type: types.EDIT_ITEM_TIPOS,
	item,
	index,
	field,
});

export const addItem = item => ({
	type: types.ADD_ITEM_BUDGET,
	item,
});

export const editTipos = tipos => ({
	type: types.EDIT_TIPOS,
	tipos,
});

export const deleteItem = index => ({
	type: types.DELETE_ITEM_BUDGET,
	index,
});

export const resetItems = () => ({
	type: types.RESET_ITEMS_BUDGET,
});

export default {
	get,
	set,
	deleteTipo,
	newTipo,
	select,
	toogleModal,
	setItems,
	editItem,
	addItem,
	editTipos,
	deleteItem,
	resetItems,
};
