import api from './api';

export const get = query =>
	api.get('/costs/item-group-line', { params: query });

export const getById = id => api.get(`/costs/item-group-line/${id}`);

export const deleteGroupLine = id => api.delete(`/costs/item-group-line/${id}`);

export const newGroupLine = group => api.post('/costs/item-group-line', group);

export const editGroupLine = (group, id) =>
	api.put(`/costs/item-group-line/${id}`, group);
