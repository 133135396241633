import api from './api';

export const get = query =>
	api.get('/laboratory/composition-feature', { params: query });

export const deleteCompositionFeature = id =>
	api.delete(`/laboratory/composition-feature/${id}`);

export const newCompositionFeature = composition =>
	api.post('/laboratory/composition-feature', composition);

export const editCompositionFeature = (composition, id) =>
	api.put(`/laboratory/composition-feature/${id}`, composition);

export const getItens = query =>
	api.get(`/laboratory/itens`, query);
