export const GET_ITEM_BOX_UNITY = 'item-box-unity/GET_ITEM_BOX_UNITY';
export const SET_ITEM_BOX_UNITY = 'item-box-unity/SET_ITEM_BOX_UNITY';
export const ADD_ITEM_BOX_UNITY = 'item-box-unity/ADD_ITEM_BOX_UNITY';
export const EDIT_ITEM_BOX_UNITY = 'item-box-unity/EDIT_ITEM_BOX_UNITY';
export const DELETE_ITEM_BOX_UNITY = 'item-box-unity/DELETE_ITEM_BOX_UNITY';
export const SET_SELECT_ITEM_BOX_UNITY =
	'item-box-unity/SET_SELECT_ITEM_BOX_UNITY';

export default {
	GET_ITEM_BOX_UNITY,
	SET_ITEM_BOX_UNITY,
	ADD_ITEM_BOX_UNITY,
	EDIT_ITEM_BOX_UNITY,
	DELETE_ITEM_BOX_UNITY,
	SET_SELECT_ITEM_BOX_UNITY,
};
