import * as types from './types';

export const getProductionSchedule = filter => ({
	type: types.GET_SCHEDULE,
	filter,
});

export const setProductionSchedule = schedule => ({
	type: types.SET_SCHEDULE,
	schedule,
});

export const getPurchaseOrders = (emprId, itemId) => ({
	type: types.GET_PURCHASE_ORDERS,
	emprId,
	itemId,
});

export const setPurchaseOrders = purchaseOrdersData => ({
	type: types.SET_PURCHASE_ORDERS,
	purchaseOrdersData,
});

export const getProgram = (data, itemId) => ({
	type: types.GET_PROGR,
	data,
	itemId
})

export const setProgram = data => ({
	type: types.SET_PROGR,
	data
})

export const setDate = (data) => ({
	type: types.SET_DATE,
	data
})

export default {
	getProductionSchedule,
	setProductionSchedule,
	getPurchaseOrders,
	setPurchaseOrders,
	getProgram,
	setDate,
	setProgram
};
