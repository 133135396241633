export const UPLOAD_FILE= 'atualizacao-tabela-vendas/UPLOAD_FILE';
export const SET_PRICE_TABLE = 'atualizacao-tabela-vendas/SET_PRICE_TABLE'
export const NEW_UPDATE = 'atualizacao-tabela-vendas/NEW_UPDATE'
export const MODAL_LOG = 'atualizacao-tabela-vendas/MODAL_LOG'
export const SET_LOGS = 'atualizacao-tabela-vendas/SET_LOGS'



export default { 
UPLOAD_FILE,
NEW_UPDATE,
SET_PRICE_TABLE,
MODAL_LOG,
SET_LOGS


}