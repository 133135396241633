import { fork } from 'redux-saga/effects';
import watchUserAuthentication from '../ducks/auth/sagas';
import watchUsers from '../ducks/users/sagas';
import watchGroups from '../ducks/groups/sagas';
import watchEnterprise from '../ducks/enterprise/sagas';
import watchGenerics from '../ducks/generics/sagas';
import watchProductionSchedule from '../ducks/production-schedule/sagas';
import watchDiscardAllocation from '../ducks/discard-allocation/sagas';
import wacthAdvertisingBudget from '../ducks/advertising-budget/sagas';
import watchCommercialDescription from '../ducks/commercial-description/sagas';
import watchGroupLine from '../ducks/item-group-line/sagas';
import watchGroupPrice from '../ducks/price-list-group/sagas';
import watchCostReport from '../ducks/cost-report/sagas';
import watchUploadTable from '../ducks/update-table/sagas';
import wacthCadTipe from '../ducks/cad-types/sagas';
import wacthDesp from '../ducks/desp-vendas/sagas';
import watchFeature from '../ducks/feature/sagas';
import watchCompositionFeature from '../ducks/composition-feature/sagas';
import watchItemBoxUnity from '../ducks/item-box-unity/sagas';
import watchItemSituation from '../ducks/item-situation';
import watchDescriptionItem from '../ducks/descriptionItem/sagas';
import watchDataSheet from '../ducks/dataSheet/sagas';
import watchGroupItens from '../ducks/group-itens/sagas';
import watchSpecificationParameters from '../ducks/specification-parameters/sagas';
import watchParametersResponse from '../ducks/parameters-response/sagas';
import watchParamsForGroup from '../ducks/params-for-group/sagas';
import watchFeatureResponse from '../ducks/featureResponse/sagas';
import watchProductLine from '../ducks/productLine/sagas';
import watchFeaturePerGroup from '../ducks/feature-per-group/sagas';
import watchSisdevin from '../ducks/sisdevin/sagas';
import watchWarehouse from '../ducks/warehouseSit/sagas';
import watchTechnicalData from '../ducks/technical-data/sagas';
import watchTransferMotives from '../ducks/transfer-motives/sagas';
import watchAdditionMotives from '../ducks/addition-motives/sagas';
import wacthDivergence from '../ducks/freDivergence/sagas';

export default function* startForman() {
	yield fork(watchUserAuthentication);
	yield fork(watchUsers);
	yield fork(watchGroups);
	yield fork(watchEnterprise);
	yield fork(watchGenerics);
	yield fork(watchProductionSchedule);
	yield fork(watchDiscardAllocation);
	yield fork(wacthAdvertisingBudget);
	yield fork(watchCommercialDescription);
	yield fork(watchGroupLine);
	yield fork(watchGroupPrice);
	yield fork(watchCostReport);
	yield fork(watchUploadTable);
	yield fork(wacthCadTipe);
	yield fork(wacthDesp);
	yield fork(watchFeatureResponse);
	yield fork(watchFeature);
	yield fork(watchCompositionFeature);
	yield fork(watchItemBoxUnity);
	yield fork(watchItemSituation);
	yield fork(watchDescriptionItem);
	yield fork(watchDataSheet);
	yield fork(watchGroupItens);
	yield fork(watchSpecificationParameters);
	yield fork(watchParametersResponse);
	yield fork(watchParamsForGroup);
	yield fork(watchProductLine);
	yield fork(watchFeaturePerGroup);
	yield fork(watchSisdevin);
	yield fork(watchWarehouse);
	yield fork(watchTechnicalData);
	yield fork(watchTransferMotives);
	yield fork(watchAdditionMotives);
	yield fork(wacthDivergence);
}
