import * as types from './types';

export const getSpecificationParameters = query => ({
	type: types.GET_SPECIFICATION_PARAMETERS,
	query,
});

export const setSpecificationParameters = feature => ({
	type: types.SET_SPECIFICATION_PARAMETERS,
	feature,
});

export const addSpecificationParameters = feature => ({
	type: types.ADD_SPECIFICATION_PARAMETERS,
	feature,
});

export const editSpecificationParameters = (feature, id) => ({
	type: types.EDIT_SPECIFICATION_PARAMETERS,
	feature,
	id,
});

export const deleteSpecificationParameters = feature => ({
	type: types.DELETE_SPECIFICATION_PARAMETERS,
	feature,
});

export const setSelectSpecificationParameters = item => ({
	type: types.SET_SELECT_SPECIFICATION_PARAMETERS,
	item,
});

export default {
	getSpecificationParameters,
	setSpecificationParameters,
	addSpecificationParameters,
	editSpecificationParameters,
	deleteSpecificationParameters,
	setSelectSpecificationParameters
};
