import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as SpecificationParametersApi from '../../../api/appApi/specification-parameters';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';

export function* get(payload) {
	yield put(apiActions.apiSubmitStart());

	const { query } = payload;

	yield put(apiActions.setQueryFilter(query));

	try {
		const response = yield call(SpecificationParametersApi.get, query);

		yield put(actions.setSpecificationParameters(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar Parâmetros.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* newSpecificationParameters(payload) {
	const { feature } = payload;

	feature.status = true;

	try {
		const response = yield call(SpecificationParametersApi.insert, feature);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetros cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/qualid/cad/specification');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao salvar Parâmetros.',
				'error',
			),
		);
	}
}

export function* editSpecificationParameters(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.feature;

	const { id } = payload;

	try {
		const response = yield call(
			SpecificationParametersApi.update,
			data,
			id,
		);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'parâmetros editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/qualid/cad/specification');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar parâmetros.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* deleteSpecificationParameters(payload) {
	const { feature } = payload;

	try {
		const response = yield call(SpecificationParametersApi.remove, feature);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Parâmetros deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			const query = yield select(apiSelectors.getQuery);
			yield put(actions.getSpecificationParameters(query));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Parâmetros.',
				'error',
			),
		);
	}
}

export default function* watchSpecificationParameters() {
	yield takeLatest(types.GET_SPECIFICATION_PARAMETERS, get);
	yield takeLatest(
		types.ADD_SPECIFICATION_PARAMETERS,
		newSpecificationParameters,
	);
	yield takeLatest(
		types.EDIT_SPECIFICATION_PARAMETERS,
		editSpecificationParameters,
	);
	yield takeLatest(
		types.DELETE_SPECIFICATION_PARAMETERS,
		deleteSpecificationParameters,
	);
}
